import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Modal, Button, Text, Group } from '@mantine/core';

import { deleteCustomer } from '../../services/customers.service';
import { Bold } from '../common/bold.component';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';

export const CustomerDeleteModal = ({ customer, show, onClose, onSuccess }) => {
  const deleteCustomerMutation = useMutation(deleteCustomer);
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = () => {
    setSubmitting(true);
    deleteCustomerMutation.mutate(customer, {
      onSuccess: () => {
        showSuccessNotification({
          message: `The customer "${customer.companyName}" was deleted successfully`,
        });
        onSuccess({});
      },
      onError: () => {
        showErrorNotification({
          message: `The customer "${customer.companyName}" was not deleted`,
        });
      },
      onSettled: () => {
        onClose();
        setSubmitting(false);
      },
    });
  };

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={onClose}
      title="Delete Customer"
    >
      <Text size="sm">Are you sure to delete <Bold>{customer && customer.companyName}</Bold> ? </Text>
      <Group position="right" mt="lg">
        <Button loading={submitting} onClick={() => handleDelete()} color="red">
          Delete
        </Button>
      </Group>
    </Modal>
  );
};
