import { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Group } from '@mantine/core';
import { IconUserMinus } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { ActionInfoContext } from '../../providers/action-info.provider';
import { UserCommonActions } from '../users/user-common-actions.component';
import { BrandUserRemoveModal } from './brand-user-remove-modal.component';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';

export const BrandUsersTableActions = ({ user, setUsers }) => {
  const { selectedBrand, selectedCustomer } = useContext(AppContext);
  const { setBrandActionInfo } = useContext(ActionInfoContext);

  const [removeBrandUserModal, setRemoveBrandUserModal] = useState(false);

  const queryClient = useQueryClient();

  const refetchBrandUsers = () => queryClient.invalidateQueries({
    queryKey: ['customerBrandUsers', selectedCustomer.id, selectedBrand.id],
  });

  return (
    <Group spacing={0}>
      <UserCommonActions
        user={user}
        setUsers={setUsers}
        customer={selectedCustomer}
        onEdit={refetchBrandUsers}
        setActionInfo={setBrandActionInfo}
      />
      <ActionIconWithInfo
        color="orange"
        actionInfo={`remove "${user.userName}" from brand "${selectedBrand.brandName}"`}
        setActionInfo={setBrandActionInfo}
        onClick={() => setRemoveBrandUserModal(true)}
      >
        <IconUserMinus size={16} />
      </ActionIconWithInfo>
      <BrandUserRemoveModal
        user={user}
        brand={selectedBrand}
        customer={selectedCustomer}
        show={removeBrandUserModal}
        onClose={() => setRemoveBrandUserModal(false)}
        onSuccess={(customer, brand, deletedUser) => {
          if (deletedUser.id == user.id) {
            setRemoveBrandUserModal(false);
          }
          setUsers(users => {
            if(selectedCustomer.id == customer.id && selectedBrand.id == brand.id) {
              return users.filter(user => user.id !== deletedUser.id);
            }
            return users;
          });
          refetchBrandUsers();
        }}
      />
    </Group>
  );
};
