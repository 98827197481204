import axios from 'axios';

export const jwtInterceptor = () => {
  //GET requests
  axios.interceptors.request.use((request) => {
    const token = localStorage.getItem('token');
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!(error.response) || error.response?.status === 401 ) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = window.location.origin;
      } else {
          let errorMessage = 'Error';
          errorMessage = error.response?.data?.title || error.response?.data;
          throw Error(errorMessage);
      }
    }
  );
};
