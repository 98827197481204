import React, { useContext, useMemo, useState } from 'react';
import { useViewportSize } from '@mantine/hooks';
import { Container, Group, Paper, Space, Stack, Tabs } from '@mantine/core';
import { IconUsers, IconTag, IconShieldLock } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { BrandsList } from '../brands/brands-list.component';
import { BrandToolbar } from '../brands/brand-toolbar.component';
import { BrandUsersTable } from '../brands/brand-users-table.component';
import { CustomerToolbar } from './customer-toolbar.component';
import { CustomerAdminToolbar } from './customer-admin-toolbar.component';
import { CustomerUsersTable } from './customer-users-table.component';
import { CustomerPasswordPolicy } from './customer-password-policy.component';
import { BrandActionInfo } from '../common/brand-action-info.component';
import { CustomerActionInfo } from '../common/customer-action-info.component';

export const CustomersPage = () => {
  const { selectedCustomer } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState('customer-users');
  const { height } = useViewportSize();

  const flexGrowStyles = { flexGrow: '1' };
  const tabsStyles = { body: { paddingTop: '0px' }};
  const mainContainerStyles = useMemo(
    () => ({ height: `${height - 70}px` }),
    [height],
  );

  return (
    <Container p={0} fluid>
      <CustomerAdminToolbar setActiveTab={setActiveTab}/>
      <Paper withBorder radius={0}>
        <Group spacing={0} align="stretch" style={mainContainerStyles} noWrap>
          <Stack spacing={0} style={flexGrowStyles}>
            <CustomerToolbar />
            <Tabs value={activeTab} onTabChange={setActiveTab} styles={tabsStyles}>
              <Tabs.List>
                <Tabs.Tab
                  value="customer-users"
                  icon={<IconUsers size={16} />}
                  disabled={!(selectedCustomer.id)}
                >
                  Users
                </Tabs.Tab>
                <Tabs.Tab
                  value="brands"
                  icon={<IconTag size={16} />}
                  disabled={!(selectedCustomer.id)}
                >
                  Brands
                </Tabs.Tab>
                <Tabs.Tab
                  value="security"
                  icon={<IconShieldLock size={16} />}
                  disabled={!(selectedCustomer.id)}
                >
                  Security
                </Tabs.Tab>
                <Space style={flexGrowStyles} />
                <CustomerActionInfo />
              </Tabs.List>
              <Tabs.Panel value="customer-users">
                <CustomerUsersTable isInView={activeTab === 'customer-users'} />
              </Tabs.Panel>
              <Tabs.Panel value="brands">
                <Paper withBorder radius={0} m="xs">
                  <Group spacing={0} radius={0} align="stretch" noWrap>
                    <BrandsList />
                    <Stack spacing={0} style={flexGrowStyles}>
                      <BrandToolbar />
                      <Tabs defaultValue="brand-users" styles={tabsStyles}>
                        <Tabs.List>
                          <Tabs.Tab
                            value="brand-users"
                            icon={<IconUsers size={14} />}
                          >
                            Brand Users
                          </Tabs.Tab>
                          <Space style={flexGrowStyles} />
                          <BrandActionInfo />
                        </Tabs.List>
                        <Tabs.Panel value="brand-users">
                          <BrandUsersTable isInView={activeTab === 'brands'} />
                        </Tabs.Panel>
                      </Tabs>
                    </Stack>
                  </Group>
                </Paper>
              </Tabs.Panel>
              <Tabs.Panel value="security">
                <CustomerPasswordPolicy isInView={activeTab === 'security'}/>
              </Tabs.Panel>
            </Tabs>
          </Stack>
        </Group>
      </Paper>
    </Container>
  );
};
