
import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Group, Modal, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconBuilding } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { postCustomer } from '../../services/customers.service';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';

export const CustomerAddModal = () => {
  const { setSelectedCustomer } = useContext(AppContext);
  const addCustomer = useMutation(postCustomer);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      companyName: '',
      address: '',
    },
  });

  const onSubmit = (customer) => {
    setIsSubmitting(true);
    addCustomer.mutate(customer, {
      onSuccess: ({ data: customer }) => {
        showSuccessNotification({
          message: `The customer "${customer.companyName}" was successfully created`,
        });
        form.reset();
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        setSelectedCustomer(customer);
      },
      onError: () => {
        showErrorNotification({
          message: `The customer "${customer.companyName}" was not created`,
        })
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    });
  };

  return (
    <>            
      <Button onClick={open} leftIcon={<IconBuilding size={16} />}>
        Create Customer
      </Button>
      <Modal
        size="md"
        zIndex="500"
        opened={opened}
        onClose={close}
        title="Create Customer"
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack spacing="lg">
            <Stack>
              <TextInput
                required
                placeholder="The customer's company name"
                label="Company Name"
                {...form.getInputProps('companyName')}
              />
              <TextInput
                placeholder='www.example.com'
                label="Web Address"
                {...form.getInputProps('address')}
              />
            </Stack>
            <Group position="right">
              <Button type="submit" loading={isSubmitting}>
                Save
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
