import { useCallback, useContext, useEffect, useState } from 'react';
import { hideNotification } from '@mantine/notifications';

import { NotificationsContext } from '../../providers/notifications.provider';
import { ActionIconWithInfo } from './action-icon-with-info.component';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';

export const NotificationActionIcon = ({
  id,
  title,
  startMessage,
  successMessage,
  failMessage,
  disabled,
  action,
  children,
  color = 'dark',
  variant = 'subtle',
  onSuccess,
  ...props
}) => {
  const { addNotification, deleteNotification, notifications } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const handleAction = useCallback(
    async () => {
      addNotification(id);
      try {
        hideNotification(id);
        setStartNotification({
          id,
          title: startMessage,
          message: '',
        });
        const response = await action();
        setSuccessNotification({
          id,
          title,
          message: successMessage(response),
        });
        if (typeof onSuccess === 'function') {
          onSuccess(response);
        }
      } catch (error) {
        setFailNotification({
          id,
          title,
          message: failMessage(error),
        });
      } finally {
        setLoading(false);
        deleteNotification(id);
      }
    },
    [
      addNotification,
      hideNotification,
      deleteNotification,
      setStartNotification,
      setSuccessNotification,
      setFailNotification,
      successMessage,
      failMessage,
      setLoading,
      onSuccess,
      action,
      id,
    ],
  );

  useEffect(() => {
    if (notifications[id]) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }), [notifications, id, setLoading];

  return (
    <ActionIconWithInfo
      disabled={disabled}
      onClick={handleAction}
      loading={loading}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </ActionIconWithInfo>
  );
};
