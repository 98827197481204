import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Modal, Button, Text, Group, Stack } from '@mantine/core';

import { NotificationsContext } from '../../providers/notifications.provider';
import { deleteCustomerBrandUser } from '../../services/customers.service';
import { Bold } from '../common/bold.component';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';

export const BrandUserRemoveModal = ({ customer, brand, user, show, onClose, onSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const { notifications, addNotification, deleteNotification } = useContext(NotificationsContext);

  const notificationKey = `remove-customer-brand-user-${customer.id}-${brand.id}-${user.id}`;

  const deleteCustomerBrandUserMutation = useMutation({
    mutationFn: deleteCustomerBrandUser,
    onSuccess: () => {
      setSuccessNotification({
        id: notificationKey,
        title: 'Removing User from Brand',
        message: `The user"${user.userName}" was removed from ${brand.brandName}`,
      });
      onSuccess(customer, brand, user);
    },
    onError: () => {
      setFailNotification({
        id: notificationKey,
        title: 'Removing User from Brand',
        message: `The user "${user.userName}" was not removed`,
      });
    },
    onSettled: () => {
      deleteNotification(notificationKey);
    },
  });

  const handleDelete = () => {
    setSubmitting(true);
    addNotification(notificationKey);
    setStartNotification({
      id: notificationKey,
      title: 'Removing User from Brand',
      message: `Removing user "${user.userName}" from "${brand.brandName}"`,
    });
    deleteCustomerBrandUserMutation.mutate({customerId: customer.id, brandId: brand.id, userId: user.id});
  };

  useEffect(() => {
    if (show) {
      if (notifications[notificationKey]) {
        setSubmitting(true);
      } else {
        setSubmitting(false);
      }
    }
  }, [user, customer, brand, show, notifications, notificationKey]);

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={onClose}
      title="Remove User from Brand"
    >
      <Stack spacing="lg" align="stretch" style={{ position: 'relative' }}>
        <Stack spacing={0} align="stretch">
          <Text size="sm">Are you sure to remove user <Bold>{user && user.userName}</Bold></Text>
          <Text size="sm">from brand <Bold>{brand && brand.brandName}</Bold> ? </Text>
        </Stack>
        <Group position="right">
          <Button loading={submitting} onClick={() => handleDelete()}>
            Remove
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
