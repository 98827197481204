import axios from 'axios';
import { Env } from '../constants/env';

const apiOldUrl = `${Env.BASE_URL}api/Brand`;
const apiUrl = `${Env.BASE_URL}api/v1/brands`;

export const getBrands = () =>
  axios.get(`${apiUrl}`);

export const createBrand = async (brand) =>
  axios.post(`${apiOldUrl}/CreateBrand`, brand);

export const updateBrand = (brand) =>
  axios.put(`${apiOldUrl}/UpdateBrand`, brand);

export const deleteBrand = (brand) =>
  axios.get(`${apiOldUrl}/DeleteBrand?brandID=${brand.id}`);

export const syncBrand = (brand) =>
  axios.get(`${apiOldUrl}/SyncBrand?brandId=${brand.id}`);
