import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Button, Group, Loader, LoadingOverlay, NumberInput, Paper, Stack, Text } from '@mantine/core';
import { IconClock, IconInfoCircle, IconMinus, IconPlus } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { NotificationsContext } from '../../providers/notifications.provider';
import { getCustomerPasswordPolicy, patchCustomerPasswordPolicy } from '../../services/customer-password-policy.service';
import { PrimarySwitch } from '../common/primary-switch.component';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';

export const CustomerPasswordPolicy = ({ isInView }) => {
  const {
    selectedCustomer,
    defaultPasswordPolicy,
    isLoadingDefaultPasswordPolicy,
  } = useContext(AppContext);
  const { notifications, addNotification, deleteNotification } = useContext(NotificationsContext);

  const [initialPasswordPolicy, setInitialPasswordPolicy] = useState({});
  const [passwordPolicy, setPasswordPolicy] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [lastModified, setLastModified] = useState('');
  
  const queryClient = useQueryClient();
  
  
  const uniqueCharactersHandlers = useRef();
  const minimumLengthHandlers = useRef();
 
  const { isLoading, isFetching } = useQuery(
    ['customerPasswordPolicy', selectedCustomer.id],
    getCustomerPasswordPolicy,
    {
      enabled: isInView && Boolean(selectedCustomer?.id),
      onSuccess: (response) => {
        setInitialPasswordPolicy({...response.data});
      },
    }
  );

  const notificationKey = `editing-customer-password-policy-${selectedCustomer.id}`;
  const isDefaultPasswordPolicy = JSON.stringify(defaultPasswordPolicy) == JSON.stringify(passwordPolicy);
  const isInitialPasswordPolicy = JSON.stringify(initialPasswordPolicy) == JSON.stringify(passwordPolicy);

  const editCustomerPasswordPolicy = useMutation({
    mutationFn: patchCustomerPasswordPolicy,
    onSuccess: (_, { customer }) => {
      setSuccessNotification({
        id: `editing-customer-password-policy-${customer.id}`,
        title: 'Update Password Policy',
        message: `Customer "${customer.companyName}" password policy updated successfully`,
      });
      queryClient.invalidateQueries({ queryKey: ['customerPasswordPolicy', customer.id] });
    },
    onError: (error, { customer }) => {
      setFailNotification({
        id: `editing-customer-password-policy-${customer.id}`,
        title: 'Update Password Policy',
        message: `Customer "${customer.companyName}" password policy was not updated`,
      });
    },
    onSettled: (_, __, { customer }) => {
      deleteNotification(`editing-customer-password-policy-${customer.id}`);
    },
  });

  const savePasswordPolicy = () => {
    setIsSaving(true);
    addNotification(notificationKey);
    setStartNotification({
      id: notificationKey,
      title: 'Update Password Policy',
      message: `Updating customer "${selectedCustomer.companyName}" password policy`,
    })
    editCustomerPasswordPolicy.mutate({customer: selectedCustomer, passwordPolicy}, {
      onSettled: () => {
        setIsSaving(false);
      }
    });
  };

  useEffect(() => {
    if (isInView) {
      setPasswordPolicy({...initialPasswordPolicy});
    }
  }, [isInView, selectedCustomer, initialPasswordPolicy, setInitialPasswordPolicy]);

  useEffect(() => {
    if (isInView) {
      if (notifications[notificationKey]) {
        setIsSaving(true);
      } else {
        setIsSaving(false);
      }
    }
  }, [isInView, notifications, notificationKey]);

  useEffect(() => {
    const modifiedDate = new Date(`${passwordPolicy.updated}Z`);
    if (modifiedDate.getFullYear() > 1) {
      const fomatedCurrentDate = modifiedDate.toLocaleDateString('en-US');
      const formatedCurrentTime = modifiedDate.toLocaleTimeString('en-US', { hour12: false });
      const modifiedBy = passwordPolicy?.updatedBy?.displayName || passwordPolicy?.updatedBy?.userName;
      setLastModified(`Last modified ${modifiedBy ? `by ${modifiedBy} ` : ''}at ${fomatedCurrentDate} ${formatedCurrentTime}`);
    } else {
      setLastModified('');
    }
  }, [passwordPolicy]);

  return (
    <Paper
      m="xs"
      radius={0}
      withBorder
      style={{ maxWidth: '686px'}}
    >
      <Text p="xs" size="sm" weight={500} style={{ background: '#f6f6f6', borderBottom: '1px solid #dee2e6' }}>
        Password Policy
      </Text>
      <Stack m="sm" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isSaving || isFetching}></LoadingOverlay>
        {(!isSaving && (isLoadingDefaultPasswordPolicy || isLoading))
          ? <Group position="center"><Loader /></Group>
          : <Stack>
            <Group align="flex-start">
              <Stack mt={4} style={{ minWidth: '312px' }}>
              <PrimarySwitch
                checked={passwordPolicy.requireDigit}
                onChange={() => setPasswordPolicy(passwordPolicy => ({
                  ...passwordPolicy,
                  requireDigit: !passwordPolicy.requireDigit
                }))}
                label="Must contain a digit"
              />
              <PrimarySwitch
                checked={passwordPolicy.requireLowercase}
                onChange={() => setPasswordPolicy(passwordPolicy => ({
                  ...passwordPolicy,
                  requireLowercase: !passwordPolicy.requireLowercase
                }))}
                label="Must contain a lower case character"
              />
              <PrimarySwitch
                checked={passwordPolicy.requireUppercase}
                onChange={() => setPasswordPolicy(passwordPolicy => ({
                  ...passwordPolicy,
                  requireUppercase: !passwordPolicy.requireUppercase
                }))}
                label="Must contain a upper case character"
              />
              <PrimarySwitch
                checked={passwordPolicy.requireNonAlphanumeric}
                onChange={() => setPasswordPolicy(passwordPolicy => ({
                  ...passwordPolicy,
                  requireNonAlphanumeric: !passwordPolicy.requireNonAlphanumeric
                }))}
                label="Must contain a non-alphanumeric character"
              />
              </Stack>
              <Stack spacing={8}>
                <Group spacing="sm">
                  <Group spacing={5}>
                    <ActionIcon size={24} color="dark-blue" variant="filled" onClick={() => uniqueCharactersHandlers.current.decrement()}>
                      <IconMinus size={16} />
                    </ActionIcon>
                    <NumberInput
                      hideControls
                      value={passwordPolicy.requiredUniqueChars}
                      onChange={(value) => setPasswordPolicy(passwordPolicy => ({
                        ...passwordPolicy,
                        requiredUniqueChars: value
                      }))}
                      handlersRef={uniqueCharactersHandlers}
                      min={0}
                      styles={{ input: { width: 36, fontSize: '13px', textAlign: 'center' } }}
                      size={28}
                    />
                    <ActionIcon size={24} color="dark-blue" variant="filled" onClick={() => uniqueCharactersHandlers.current.increment()}>
                      <IconPlus size={16} />
                    </ActionIcon>
                  </Group>
                  <Text size={13}>Minimum number of unique characters</Text>
                </Group>
                <Group spacing="sm">
                  <Group spacing={5}>
                    <ActionIcon size={24} color="dark-blue" variant="filled" onClick={() => minimumLengthHandlers.current.decrement()}>
                      <IconMinus size={16} />
                    </ActionIcon>
                    <NumberInput
                      hideControls
                      value={passwordPolicy.requiredLength}
                      onChange={(value) => setPasswordPolicy(passwordPolicy => ({
                        ...passwordPolicy,
                        requiredLength: value
                      }))}
                      handlersRef={minimumLengthHandlers}
                      min={0}
                      styles={{ input: { width: 36, fontSize: '13px', textAlign: 'center' } }}
                      size={28}
                    />
                    <ActionIcon size={24} color="dark-blue" variant="filled" onClick={() => minimumLengthHandlers.current.increment()}>
                      <IconPlus size={16} />
                    </ActionIcon>
                  </Group>
                  <Text size={13}>Minimum length</Text>
                </Group>
              </Stack>
            </Group>
            <Group position="apart">
              <Group spacing="xs" align="start" noWrap>
                {(!isLoadingDefaultPasswordPolicy && isDefaultPasswordPolicy && !isFetching) && <>
                  <IconInfoCircle size={22} color="gray" />
                  <Text size="sm" color="gray" style={{ fontStyle: 'italic'}}>
                    This is the Talkoot default password policy
                  </Text>
                </>}
                {!isLoadingDefaultPasswordPolicy && lastModified && !isFetching && <>
                  <IconClock size={22} color="gray" />
                  <Text size="sm" color="gray" style={{ fontStyle: 'italic'}}>
                    {lastModified}
                  </Text>
                </>}
              </Group>
              <Button
                color="dark-blue"
                disabled={isInitialPasswordPolicy || isSaving}
                onClick={savePasswordPolicy}
              >
                Save
              </Button>
            </Group>
          </Stack>
        }
      </Stack>
    </Paper>
  );
};
