import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Center,
  Group,
  Image,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { resetPassword, checkUserWebsites, getUserPasswordPolicy } from '../../services/account.service';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';
import logo from '../../assets/talkoot_transparent_460x126.png'
import { getPasswordRequirements, getPasswordStrength } from './password.helpers';
import { PasswordStrengthBars } from './password-strength-bars.component';
import { PasswordRequirement } from './password-requirements.component';
import WebAppsList from './wep-apps-list';

export const ResetPassword = () => {
  const [showWebappList, setShowWebappList] = useState(false)
  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState(false);
  const [touched, setTouched] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [webApps, setWebApps] = useState([]);

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const username = searchParams.get('username');
  const token = searchParams.get('token').replaceAll(' ', '+');

  const { isLoading: isLoadingUserPasswordPolicy } = useQuery(
    ['userPasswordPolicy', username, token],
    getUserPasswordPolicy,
    {
      onSuccess: (response) => {
        if (response.data == 'Token is not valid') {
          setIsTokenValid(false);
          return;
        }
        const requirements = getPasswordRequirements(response.data);
        setRequirements(requirements);
      },
    }
  );

  const onReset = async ({ password, confirmPassword }) => {
    const formValues = { username, token, password, confirmPassword };
    setSubmitting(true);
    try {
      const response = await resetPassword(formValues);
      if (response?.data?.succeeded) {
        showSuccessNotification({
          message: 'Password successfully changed',
        });
        const userRole = searchParams.get('roles');
        if(userRole.includes('admin')) {
          navigate('/login');
        } else {
          webAppItems();
        }
      } else {
        showErrorNotification({
          message: response?.data?.errors[0]?.description || 'Failed to reset password',
        });
        }
    } catch (error) {
      showErrorNotification({
        message: error?.message || 'Failed to reset password',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const webAppItems = async () => {
    const username = searchParams.get('username')
    checkUserWebsites(username)
      .then((res) => {
      let webAppList = res.data;
      const webApps = webAppList.webUrls;
      if (webApps.length === 1) {
        window.location.href = webApps[0];
      } else {
        setWebApps(webApps)
        setShowWebappList(true);
      }
      })
      .catch(err => console.error(err));
  }       

  return (
    <Center style={{ width: '100%', height: '100vh' }}>
      <Stack align="center" style={{ marginTop: '-120px' }}>
        <Image
          align="center"
          width={300}
          src={logo}
          alt="Talkoot"
        />
        <Text weight={600} color="dimmed" align="center" mt="xs" mb="md">
          Reset Account Password
        </Text>
        <Group position="center">
          <form onSubmit={form.onSubmit(onReset)}>
            <LoadingOverlay visible={isLoadingUserPasswordPolicy} />
            {!isTokenValid ?
              <Text>The password reset link is invalid or has expired</Text> :
              <Stack style={{width: '350px'}}>
                <Stack spacing={0}>
                  <PasswordInput
                    placeholder="Password"
                    label="New password"
                    required
                    {...form.getInputProps('password')}
                    onBlur={() => setTouched(true)}
                  />
                  <PasswordStrengthBars password={form.values.password} requirements={requirements} />
                  {touched &&
                    Boolean(requirements.length) &&
                    requirements.map((requirement, index) => (
                      <PasswordRequirement
                        key={index}
                        label={requirement.label}
                        meets={requirement.re.test(form.values.password)}
                      />
                    ))
                  }
                </Stack>
                <PasswordInput
                  placeholder="Password"
                  label="Confirm new password"
                  required
                  {...form.getInputProps('confirmPassword')}
                />
                <Button
                  fullWidth
                  type="submit"
                  loaderPosition="right"
                  loading={submitting}
                  disabled={
                    getPasswordStrength(form.values.password, requirements) !== 100
                    || form.values.password !== form.values.confirmPassword
                  }
                >
                  Submit
                </Button>
              </Stack>
            }
          </form>
        </Group>
      </Stack>
      <WebAppsList
        opened={showWebappList}
        closeModal={() => setShowWebappList(false)}
        items={webApps}
      />
    </Center>
  );
};
