import React, { useState } from 'react';
import { Button, Center, Image, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { forgotPassword } from '../../services/account.service';
import { Bold } from '../common/bold.component';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';
import logo from '../../assets/talkoot_transparent_460x126.png'

export const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      username: '',
    },
  });

  const onForgotPassword = async ({ username }) => {
    setLoading(true);
    try {
      const response = await forgotPassword(username);
      if (response?.status == 200) {
        showSuccessNotification({
          message: 'Please check your email to reset your password'
        });
      }
    } catch (error) {
      showErrorNotification({
        message: error?.message || 'Something went wrong',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center style={{ width: '100%', height: '100vh' }}>
      <Stack spacing={0} align="center" style={{ marginTop: '-120px' }}>
        <Image width={300} src={logo} alt="Talkoot" />
        <Text weight={600} color="dimmed" align="center" mt="xs" mb="md">
          Forgot Password
        </Text>
        <form onSubmit={form.onSubmit(onForgotPassword)}>
          <Stack style={{width: '350px'}}>
            <Text size="sm">
              Enter your <Bold>username</Bold> and we will email you a reset link.
            </Text>
            <TextInput
              required
              placeholder="Username"
              disabled={loading}
              {...form.getInputProps('username')}
            />
            <Button
              fullWidth
              type="submit"
              loaderPosition="right"
              loading={loading}
            >
              Send reset link
            </Button>
          </Stack>
        </form>
      </Stack>
    </Center>
  );
};
