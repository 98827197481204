import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  LoadingOverlay,
  Group,
  Modal,
  TextInput,
  Divider,
  PasswordInput,
  Select,
  Stack,
  Loader,
  Text,
} from '@mantine/core';
import { useForm, zodResolver, } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconUserPlus } from '@tabler/icons-react';
import { z } from 'zod';

import { AppContext } from '../../providers/app-provider';
import { createCustomerUser } from '../../services/customers.service';
import { PasswordRequirement } from '../auth/password-requirements.component';
import { getPasswordRequirements, getPasswordStrength } from '../auth/password.helpers';
import { PasswordStrengthBars } from '../auth/password-strength-bars.component';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';
import { HiddenInput } from '../common/hidden-input.component';
import { Label } from '../common/label.component';
import { getCustomerPasswordPolicy } from '../../services/customer-password-policy.service';

const validationSchema = z.object({
  userName: z.string().min(1, { message: 'Username is required' }),
  email: z.string().min(1, { message: 'Email is required' }).email({ message: 'Invalid email' }),
  role: z.string().min(1, { message: 'Role is required' }),
});

export const UserAddModal = ({ onUserAddSuccess, disabled = false, forCustomer = false }) => {
  const {
    roles,
    isLoadingRoles,
    customers,
    isLoadingCustomers,
    selectedCustomer,
  } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const emailRef = useRef(null);

  const form = useForm({
    schema: zodResolver(validationSchema),
    initialValues: {
      userName: '',
      email: '',
      firstName: '',
      lastName: '',
      displayName: '',
      jobTitle: '',
      password: '',
      confirmPassword: '',
      customerId: 0,
      role: 'read-only',
    },
  });

  const addUserMutation = useMutation(createCustomerUser);

  const { isLoadingCustomerPasswordPolicy, isFetching: isFetchingCustomerPasswordPolicy } = useQuery(
    ['customerPasswordPolicy', form.values.customerId],
    getCustomerPasswordPolicy,
    {
      enabled: opened && Boolean(form.values.customerId),
      onSuccess: ({ data: customerPasswordPolicy }) => {
        const requirements = getPasswordRequirements(customerPasswordPolicy);
        setRequirements(requirements);
      },
    }
  );

  const selectCustomers = useMemo(
    () => customers.map((customer) => ({ value: customer.id.toString(), label: customer.companyName })),
    [customers],
  );

  const onSubmit = (values) => {
    setPasswordTouched(true);
    setConfirmPasswordTouched(true);
    const passwordStrength = getPasswordStrength(values.password, requirements);
    if (passwordStrength < 100 || values.password !== values.confirmPassword) {
      return;
    }
    setSubmitting(true);
    addUserMutation.mutate(
      {
        customer: selectedCustomer,
        user: values,
      },
      {
        onSuccess: ({ data: user }) => {
          showSuccessNotification({
            message: 'User created successfully',
          });
          onUserAddSuccess(user);
          form.reset();
          close();
        },
        onError: (error) => {
          showErrorNotification({
            message: error?.message || 'User was not created',
          });
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  const onFirstNameInputChange = (event) => {
    const value = event.currentTarget.value;
    form.setFieldValue('firstName', value);

    const { firstName, displayName } = form.values;
    if ( displayName == '' || displayName.trim() == firstName) {
      form.setFieldValue('displayName', value);
    }
  };

  const onLastNameInputChange = (event) => {
    const value = event.currentTarget.value;
    form.setFieldValue('lastName', value);

    const { firstName, lastName, displayName } = form.values;
    if (displayName == firstName || displayName == `${firstName} ${lastName}`) {
      form.setFieldValue('displayName', `${firstName} ${value}`);
    }
  };

  const onEmailInputChange = (event) => {
    const value = event.currentTarget.value;
    form.setFieldValue('email', value);

    const { email, userName } = form.values;
    if (userName == '' || userName == email) {
      form.setFieldValue('userName', value);
    }
  }

  useEffect(() => {
    if (opened) {
      setTimeout(() => {
        emailRef?.current?.focus();
      }, 50);
    }
  }, [opened]);

  useEffect(() => {
    if (opened && selectedCustomer?.id) {
      form.setFieldValue("customerId", selectedCustomer.id.toString());
    }
  }, [selectedCustomer, opened]);

  return (
    <>
      <Button
        onClick={open}
        leftIcon={<IconUserPlus size={16} />}
        disabled={isLoadingCustomers || !selectedCustomer?.id || disabled}
      >
        Add New User
      </Button>
      <Modal
        size={820}
        zIndex={500}
        opened={opened}
        onClose={() => {
          form.reset();
          setSubmitting(false);
          setPasswordTouched(false);
          setConfirmPasswordTouched(false);
          close();
        }}
        title='Add New User'
      >
        <form onSubmit={form.onSubmit(onSubmit)} style={{ position: 'relative' }} autoComplete="off">
          <LoadingOverlay visible={isLoadingCustomerPasswordPolicy || isLoadingRoles} />
          <Group spacing={40} align="flex-start" grow>
            <Stack>
              <Stack spacing="xs">
                <TextInput
                  ref={emailRef}
                  placeholder="your@mail.com"
                  label={<Label required>E-mail</Label>}
                  autoComplete="email"
                  disabled={submitting}
                  {...form.getInputProps('email')}
                  value={form.values.email}
                  onChange={onEmailInputChange}
                />
                <TextInput
                  placeholder="Username"
                  label={<Label required>Username</Label>}
                  disabled={submitting}
                  {...form.getInputProps('userName')}
                />
                <Select
                  zIndex="600"
                  data={roles.map(role => ({ value: role.name, label: role.name }))}
                  label={<Label required>Role</Label>}
                  placeholder="User Role"
                  disabled={submitting}
                  searchable
                  {...form.getInputProps('role')}
                />
              </Stack>
              <Divider label="Optional User Details" labelPosition="center" />
              <Group grow>
                <TextInput
                  placeholder="First name"
                  label={<Label required>First Name</Label>}
                  disabled={submitting}
                  value={form.values.firstName}
                  onChange={onFirstNameInputChange}
                />
                <TextInput
                  placeholder="Last name"
                  label={<Label required>Last Name</Label>}
                  disabled={submitting}
                  value={form.values.lastName}
                  onChange={onLastNameInputChange}
                />
              </Group>
              <Group grow>
                <TextInput
                  placeholder="Display name"
                  label={<Label required>Display name</Label>}
                  disabled={submitting}
                  {...form.getInputProps('displayName')}
                />
                <TextInput
                  placeholder="Job title"
                  label="Job title"
                  disabled={submitting}
                  autoComplete="off"
                  {...form.getInputProps('jobTitle')}
                />
              </Group>
            </Stack>
            <Stack>
              <Stack spacing="xs">
                <Select
                  zIndex="600"
                  data={selectCustomers}
                  label="Customer"
                  placeholder="Customer"
                  searchable
                  required
                  disabled={isLoadingCustomers || isFetchingCustomerPasswordPolicy || forCustomer}
                  {...form.getInputProps('customerId')}
                />
                <Stack spacing={0}>
                  <HiddenInput />
                  <PasswordInput
                    placeholder="Password"
                    label={
                      <Group spacing={100}>
                        <Label required>Password</Label>
                        {isFetchingCustomerPasswordPolicy &&
                          <Group spacing={6}>
                            <Loader size="xs" variant="dots" />
                            <Text fs="italic" color="gray" weight={400}>
                              loading the password policy
                            </Text>
                          </Group>
                        }
                      </Group>
                    }
                    disabled={submitting}
                    autoComplete="new-password"
                    value={form.values.password}
                    onChange={(event) => {
                      form.setFieldValue('password', event.currentTarget.value);
                      setPasswordTouched(true);
                      setConfirmPasswordTouched(true);
                    }}
                    onBlur={() => {
                      setPasswordTouched(true);
                      setConfirmPasswordTouched(true);
                    }}
                  />
                  {passwordTouched && <PasswordStrengthBars password={form.values.password} requirements={requirements}/>}
                  {passwordTouched &&
                    Boolean(requirements.length) &&
                    requirements.map((requirement, index) => (
                      <PasswordRequirement
                        key={index}
                        label={requirement.label}
                        meets={requirement.re.test(form.values.password)}
                      />
                    ))
                  }
                </Stack>
                <Stack spacing={0} mt={passwordTouched ? -6 : 0}>
                  <PasswordInput
                    placeholder="Confirm password"
                    label={<Label required>Confirm Password</Label>}
                    disabled={submitting}
                    autoComplete="new-password"
                    value={form.values.confirmPassword}
                    onChange={(event) => {
                      form.setFieldValue('confirmPassword', event.currentTarget.value);
                      setPasswordTouched(true);
                      setConfirmPasswordTouched(true);
                    }}
                    onBlur={() => {
                      setPasswordTouched(true);
                      setConfirmPasswordTouched(true);
                    }}
                    />
                  {confirmPasswordTouched && requirements.length > 0 && (
                    <PasswordRequirement
                    label="Must match the password"
                    meets={form.values.password.length > 0 && form.values.password === form.values.confirmPassword}
                    />
                    )}
                </Stack>
              </Stack>
            </Stack>
          </Group>
          <Group position="right" mt="md">
            <Button
              type="submit"
              loaderPosition="right"
              loading={submitting}
              disabled={isFetchingCustomerPasswordPolicy}
            >
              Save
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};
