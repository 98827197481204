import { useCallback, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Group, Text } from '@mantine/core';
import { IconEdit, IconRefresh, IconTrash } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { ActionInfoContext } from '../../providers/action-info.provider';
import { AuthContext } from '../../providers/auth-provider';
import { syncBrand } from '../../services/brands.service';
import { BrandEditModal } from './brand-edit-modal.component';
import { BrandDeleteModal } from './brand-delete-modal.component';
import { NotificationActionIcon } from '../common/notification-action-icon.component';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';

export const BrandToolbar = () => {
  const { isAdmin } = useContext(AuthContext);
  const { selectedBrand, setSelectedBrand } = useContext(AppContext);
  const { setBrandActionInfo } = useContext(ActionInfoContext);

  const [editBrandModal, setEditBrandModal] = useState(false);
  const [deleteBrandModal, setDeleteBrandModal] = useState(false);

  const brandName = selectedBrand?.brandName || '';

  const queryClient = useQueryClient();

  const onActionSuccess = (brand) => {
    queryClient.invalidateQueries({ queryKey: ['brands'] });
    setSelectedBrand(brand);
  };

  const successMessage = useCallback(
    () => `The ${brandName} brand sync was successful`,
    [brandName],
  );

  const failMessage = useCallback(
    error => (error?.message || `The ${brandName} brand sync failed`),
    [brandName],
  );

  const syncBrandAction = useCallback(
    async () => await syncBrand(selectedBrand),
    [selectedBrand],
  );

  const toolbarStyles = {backgroundColor: '#f6f6f6', minHeight: '49px', borderBottom: '1px solid #dee2e6'};

  return (
    <Group
      p="xs"
      pl="md"
      position="apart"
      style={toolbarStyles}
    >
      {brandName ?
      <Group spacing="xs" style={{ minWidth: '250px' }}>
        <Text size="xs" color="gray">BRAND</Text>
        <Text size="md" weight={500}>{brandName}</Text>
        <Text size="xs" color="gray">ID {selectedBrand.id}</Text>
      </Group> :
        <Text size="sm" color="gray"><em>Select a brand</em></Text>
      }
      <Group spacing="xs">
        <NotificationActionIcon
          id={`sync-brand-${selectedBrand.id}`}
          title={`Sync brand "${brandName}"`}
          startMessage={ `Syncing brand "${brandName}"`}
          successMessage={successMessage}
          failMessage={failMessage}
          action={syncBrandAction}
          disabled={!selectedBrand?.id}
          actionInfo="sync brand info"
          setActionInfo={setBrandActionInfo}
        >
          <IconRefresh size={18} />
        </NotificationActionIcon>
        {isAdmin &&
          <>
            <ActionIconWithInfo
              disabled={!(selectedBrand?.id)}
              actionInfo="edit brand info"
              setActionInfo={setBrandActionInfo}
              onClick={() => setEditBrandModal(true)}
            >
              <IconEdit size={16}/>
            </ActionIconWithInfo>
            <ActionIconWithInfo
              disabled={!(selectedBrand?.id)}
              actionInfo ="delete brand"
              setActionInfo={setBrandActionInfo}
              onClick={() => setDeleteBrandModal(true)}
            >
              <IconTrash size={16}/>
            </ActionIconWithInfo>
          </>
        }
      </Group>
      <BrandEditModal
        brand={selectedBrand}
        show={editBrandModal}
        onClose={() => setEditBrandModal(false)}
        onSuccess={onActionSuccess}
      />
      <BrandDeleteModal
        brand={selectedBrand}
        show={deleteBrandModal}
        onClose={() => setDeleteBrandModal(false)}
        onSuccess={onActionSuccess}
      />
    </Group>
  );
};
