import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Modal, Button, Text, Group } from '@mantine/core';

import { AppContext } from '../../providers/app-provider';
import { deleteCustomerBrand } from '../../services/customers.service';
import { Bold } from '../common/bold.component';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';

export const BrandDeleteModal = ({ show, onClose, onSuccess }) => {
  const { selectedBrand } = useContext(AppContext);
  const removeBrand = useMutation(deleteCustomerBrand);
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = () => {
    setSubmitting(true);
    removeBrand.mutate(selectedBrand, {
      onSuccess: () => {
        showSuccessNotification({
          message: `The brand "${selectedBrand.brandName}" was successfully removed`,
        });
        onSuccess({});
      },
      onError: () => {
        showErrorNotification({
          message: `The brand "${selectedBrand.brandName}" was not deleted`,
        });
      },
      onSettled: () => {
        onClose();
        setSubmitting(false);
      },
    });
  };

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={onClose}
      title="Delete Brand"
    >
      <Text size="sm">Are you sure to delete <Bold>{selectedBrand.brandName}</Bold> ? </Text>
      <Group mt="lg" position="right">
        <Button onClick={() => handleDelete()} loading={submitting}>
          Delete
        </Button>
      </Group>
    </Modal>
  );
};
