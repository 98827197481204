import { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Autocomplete, Button, Group, Loader } from '@mantine/core';
import { IconUser, IconUserPlus, IconX } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { getUsers } from '../../services/users.service';
import { postCustomerUser } from '../../services/customers.service';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';

export const CustomerAddUserToolbar = ({
  customerUsers,
  setCustomerUsers,
  setAddCustomerUserMode,
}) => {
  const { selectedCustomer, setSelectedCustomer } = useContext(AppContext);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [addingUserToCustomer, setAddingUserToCustomer] = useState(false);
  const [autoSearchUserValue, setAutoSearchUserValue] = useState('');

  const queryClient = useQueryClient();

  const { data: usersResponse, isLoading: isLoadingUserSearch } = useQuery(
    ['usersSearch', autoSearchUserValue, 25, 1, ''],
    getUsers,
  );

  const postCustomerUserMutation = useMutation({
    mutationFn: postCustomerUser,
    onSuccess: (_, variables) => {
      const { customer, user } = variables;
      const notificationKey = `adding-customer-user-${customer.id}-${user.id}`;
      setSuccessNotification({
        id: notificationKey,
        title: 'Adding User to Customer',
        message: `The user "${user.userName}" was added to customer "${customer.companyName}" successfully`,
      });
      queryClient.invalidateQueries({ queryKey: ['customerUsers', customer.id] });
      setSelectedCustomer(selectedCustomer => {
        if (selectedCustomer.id == customer.id) {
          setCustomerUsers(customerUsers => {
            if (!customerUsers.find(customerUser => customerUser.id == user.id) ) {
              return [user, ...customerUsers ]
            }
            return customerUsers;
          });    
        }
        return selectedCustomer;
      });
    },
    onError: () => {
      setFailNotification({
        id: notificationKey,
        title: 'Adding User to Customer',
        message: `The user "${user.userName}" was not added to customer "${customer.companyName}"`,
      });
    },
    onSettled: () => {
      setSearchUserValue('');
      setAddingUserToCustomer(false);
    },
  });

  const handleAutocompleteChange = (value) => {
    setSearchUserValue(value);
    clearTimeout(searchUserTimer);
    searchUserTimer = setTimeout(() => {
      setAutoSearchUserValue(value);
    }, 500);
  };

  const handleAddUser = async () => {
    const user = usersResponse.data.users.filter(user => user.userName === searchUserValue)[0];
    setAddingUserToCustomer(true);
    const notificationKey = `adding-customer-user-${selectedCustomer.id}-${user.id}`;
    setStartNotification({
      id: notificationKey,
      title: 'Adding User to Customer',
      message: `Adding user "${user.userName}" to customer "${selectedCustomer.companyName}"`,
    });
    postCustomerUserMutation.mutate({ customer: selectedCustomer, user });
  };
  
  let searchUserTimer;

  return (
    <Group p="xs" position="apart" style={{ backgroundColor: '#5d71a9', borderBottom: '1px solid #5d71a9'}}>
      <Group>
        <Autocomplete
          autoFocus
          disabled={addingUserToCustomer}
          value={searchUserValue}
          placeholder="Search for existing users"
          data={
            searchUserValue
            ? (usersResponse?.data?.users || [])
              .map(user => user.userName)
              .filter(userName => !customerUsers.map(user => user.userName).includes(userName))
            : []
          }
          icon={isLoadingUserSearch ?  <Loader size={18} /> : <IconUser />}
          variant="unstyled"
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '320px'}}
          limit={20}
          onChange={handleAutocompleteChange}
          onItemSubmit={() => clearTimeout(searchUserTimer)}
          rightSection={
            <ActionIcon onClick={() => setSearchUserValue('')}>
              <IconX size={16}/>
            </ActionIcon>
          }
        />
        <Button
          variant="white"
          disabled={!((usersResponse?.data?.users || []).map(user => user.userName).includes(searchUserValue)) || addingUserToCustomer}
          loading={addingUserToCustomer}
          onClick={handleAddUser}
          leftIcon={<IconUserPlus size={16} />}
        >
          {`Add User to "${selectedCustomer.companyName}"`}
        </Button>
      </Group>
      <Button
        variant="white"
        leftIcon={<IconX size={16} />}
        onClick={() => { setAddCustomerUserMode(false); setSearchUserValue(''); }}
        disabled={addingUserToCustomer}
      >
        Close
      </Button>
    </Group>
  );
};
