import { useCallback } from 'react';
import { ActionIcon } from '@mantine/core';

export const ActionIconWithInfo = ({
  children,
  setActionInfo,
  actionInfo = '',
  color = 'dark',
  onClick,
  ...props
}) => {
  const handleMouseEnter = useCallback(
    () => setActionInfo(actionInfo),
    [actionInfo, setActionInfo],
  );

  const handleMouseLeave = useCallback(
    () => setActionInfo(''),
    [setActionInfo],
  );

  const handleClick = useCallback(
    () => {
      setTimeout(() => {
        setActionInfo('');
      }, 0);
      onClick();
    },
    [setActionInfo, onClick],
  );

  return (
    <ActionIcon
      radius="xl"
      color={color}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
      onClick={handleClick}
    >
      {children}
    </ActionIcon>
  );
};
