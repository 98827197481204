import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import jwt from 'jwt-decode';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { login } from '../services/account.service';

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const appInsights = useAppInsightsContext();
  const queryClient = useQueryClient();

  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const authContext = {
    user,
    setUser,
    isAdmin,
    login: async(username, password) => {
      return login({ username, password })
        .then( response => {
          let { data: { access_token: token }} = response;
          const user = jwt(token);
          setUser(user);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', token);
          return ({ success: true });
        })
        .catch(error => {
          appInsights.trackException({ exception: error.message });
          return ({ success: false, message: error.message });
        });
    },
    logout: () => {
      try {
        queryClient.clear();
        localStorage.clear();
        setUser(null);
      } catch(error){
        appInsights.trackException({ exception: error });
      }
    },
  };
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    setIsAdmin(user?.role === 'admin');
  }, [user]);

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};
