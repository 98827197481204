import { Text } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

export const showErrorNotification = ({ title, message = '', autoClose = false }) => {
  showNotification({
    icon: <IconX size={18} />,
    color: 'red',
    title,
    message,
    autoClose,
  });
};

export const showSuccessNotification = ({ title, message = '', autoClose = true }) => {
  showNotification({
    icon: <IconCheck size={18} />,
    color: 'teal',
    title,
    message,
    autoClose,
  });
};

export const setStartNotification = ({ id, title, message }) => {
  showNotification({
    id,
    title: <Text size="sm" style={{ fontStyle: 'italic'}}>{title}</Text>,
    message,
    loading: true,
    autoClose: false,
    withCloseButton: false,
  });
};

export const setSuccessNotification = ({ id, title, message }) => {
  updateNotification({
    id,
    title,
    message,
    icon: <IconCheck size={18} />,
    color: 'teal',
    autoClose: 6000,
  });
};

export const setFailNotification = ({ id, title, message }) => {
  updateNotification({
    id,
    title,
    message,
    icon: <IconX size={18} />,
    color: 'red',
    autoClose: false,
  });
};
