import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppContext } from '../../providers/app-provider';

import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';

import { patchCustomerBrand } from '../../services/customers.service';

export const BrandEditModal = ({ show, onClose, onSuccess }) => {
  const { selectedBrand } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const form = useForm({
    initialValues: {
      brandName: '',
    },
  });

  const updateBrandName = useMutation(patchCustomerBrand);

  const onSubmit = (values) => {
    setSubmitting(true);
    const editedBrand = {
      ...selectedBrand,
      ...values,
    };
    updateBrandName.mutate(editedBrand, {
      onSuccess: () => {
        showSuccessNotification({
          message: `The brand "${editedBrand.brandName}" was successfully updated`,
        });
        form.reset();
        onSuccess(editedBrand);
      },
      onError: () => {
        showErrorNotification({
          message: `The brand "${editedBrand.brandName}" was not updated`,
        });
      },
      onSettled: () => {
        onClose();
        setSubmitting(false);
      },
    });
  };

  useEffect(() => {
    if (selectedBrand?.id) {
      form.setValues({
        brandName: selectedBrand.brandName,
      });
    }
  }, [selectedBrand]);

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={onClose}
      title="Edit Brand"
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Group grow>
          <TextInput
            required
            placeholder='Brand Name'
            label='Brand Name'
            {...form.getInputProps('brandName')}
          />
        </Group>
        <Group mt="md" position="right">
          <Button type="submit" loading={submitting}>
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
