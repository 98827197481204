import React from 'react';
import { Box, Text, Center } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

export const PasswordRequirement = ({ meets, label }) => {
  return (
    <Text color={meets ? 'teal' : 'red'} mt={5} size="sm">
      <Center inline>
        {meets ? <IconCheck size={14} /> : <IconX size={14} />}
        <Box ml={5}>{label}</Box>
      </Center>
    </Text>
  );
};
