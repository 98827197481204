import { useState } from 'react';
import { Group } from '@mantine/core';
import { IconEdit, IconLockOpen } from '@tabler/icons-react';

import { UserSyncAction } from './user-sync-action.component';
import { UserEditModal } from './user-edit-modal.component';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';
import { UserResetPasswordModal } from './user-reset-password-modal.component';
import { UserLogonReportAction } from './user-logon-report-action.component';

export const UserCommonActions = ({ user, setUsers, onEdit, setActionInfo = () => {}, customer = {} }) => {
  const [editUserModal, setEditUserModal] = useState(false);
  const [resetPasswordUserModal, setResetPasswordUserModal] = useState(false);

  return (
    <Group spacing={0}>
      <ActionIconWithInfo
        setActionInfo={setActionInfo}
        actionInfo={`edit user "${user.userName}"`}
        onClick={() => setEditUserModal(true)}
      >
        <IconEdit size={16}/>
      </ActionIconWithInfo>
      <ActionIconWithInfo
        setActionInfo={setActionInfo}
        actionInfo={`reset password for user "${user.userName}"`}
        onClick={() => setResetPasswordUserModal(true)}
      >
        <IconLockOpen size={16} />
      </ActionIconWithInfo>
      <UserSyncAction user={user} customer={customer} setActionInfo={setActionInfo} />
      <UserLogonReportAction user={user} customer={customer} setActionInfo={setActionInfo} />
      <UserEditModal
        user={user}
        show={editUserModal}
        closeModal={() => setEditUserModal(false)}
        onAccept={(editedUser) => {
          if (editedUser.id == user.id) {
            setEditUserModal(false);
          }
          setUsers(users => {
            return users.map(user => {
              if (user.id == editedUser.id) {
                return { ...editedUser };
              }
              return user;
            });
          });
          onEdit();
        }}
      />
      <UserResetPasswordModal
        user={user}
        show={resetPasswordUserModal}
        closeModal={() => setResetPasswordUserModal(false)}
      />
    </Group>
  );
};
