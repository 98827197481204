import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Select } from '@mantine/core';

import { AppContext } from '../../providers/app-provider';

export const CustomersSelect = () => {
  const {
    customers,
    isLoadingCustomers,
    selectedCustomer,
    setSelectedBrand,
    setSelectedCustomer,
  } = useContext(AppContext);
  const [value, setValue] = useState(null);

  const filteredCustomers = useMemo(
    () => customers.map(customer => ({ value: customer.id.toString(), label: customer.companyName, ...customer })),
    [value, customers],
  );

  const filterFunction = (value, customer) => {
    return customer.companyName.toLocaleLowerCase().includes(value.toLocaleLowerCase());
  };

  const itemComponent = forwardRef(
    ({ value, label, id, companyName, address, webApplicationId, ...others }, ref) => (
      <div ref={ref} {...others}>
        {companyName}
      </div>
    ),
  );

  const onChange = useCallback(
    (value) => {
      setValue(value);
      if (value) {
        setSelectedCustomer(customers.filter(customer => customer.id === parseInt(value))[0]);
        setSelectedBrand({});
      }
    },
    [setValue, setSelectedBrand, setSelectedCustomer, customers],
  );

  const styles = { width: '220px' };

  useEffect(() => {
    if (selectedCustomer?.id) {
      setValue(selectedCustomer.id.toString());
    }
  }, [selectedCustomer]);

  return (
    <Select
      zIndex={600}
      data={filteredCustomers}
      filter={filterFunction}
      itemComponent={itemComponent}
      value={value}
      onChange={onChange} 
      hoverOnSearchChange
      maxDropdownHeight={800}
      disabled={isLoadingCustomers}
      allowDeselect={false}
      radius={0}
      nothingFound="Nothing found"
      placeholder="Select customer"
      searchable
      style={styles}
    />
  );
};
