import axios from 'axios';
import { Env } from '../constants/env';

const apiUrl = `${Env.BASE_URL}api/v1/customers`;

export const getCustomerPasswordPolicy = ({ queryKey: [_, customerId] }) =>
  axios.get(`${apiUrl}/${customerId}/password-policy`);

export const patchCustomerPasswordPolicy = ({ customer, passwordPolicy}) => 
  axios.patch(`${apiUrl}/${customer.id}/password-policy`, passwordPolicy);
