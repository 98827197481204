import { useContext } from 'react';

import { ActionInfoContext } from '../../providers/action-info.provider';
import { InfoText } from './info-text.component';

export const CustomerActionInfo = () => {
  const { customerActionInfo } = useContext(ActionInfoContext);

  return (
    <InfoText>{customerActionInfo}</InfoText>
  );
};
