import axios from 'axios';
import { Env } from '../constants/env';

const apiUrl = `${Env.BASE_URL}api/v1/account`;

export const login = (loginUser) =>
  axios.post(`${apiUrl}/admin-login`, loginUser);

export const forgotPassword = (username) =>
  axios.get(`${apiUrl}/reset-password?username=${username}&appUrl=${window.location.origin}`);
  
export const getResetPasswordLink = (username) =>
  axios.get(`${apiUrl}/reset-password-url?username=${username}&appUrl=${window.location.origin}`);

export const resetPassword = (formValues) =>
  axios.post(`${apiUrl}/reset-password`, formValues);

export const adminResetPassword = (formValues) =>
  axios.post(`${apiUrl}/admin-reset-password`, formValues);

export const getDefaultPasswordPolicy = () =>
  axios.get(`${apiUrl}/password-policy`);

export const getUserPasswordPolicy = ({ queryKey: [_, username, token] }) =>
  axios.post(`${apiUrl}/user-password-policy`, { username, token });

export const checkUserWebsites = (username) => 
  axios.get(`${apiUrl}/check-websites?username=${username}`);

export const testSync = (customer) => 
  axios.get(`${apiUrl}/testsync?customerID=${customer.id}`);
