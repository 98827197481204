import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Group, Text } from '@mantine/core';
import { IconRefresh, IconTableExport, IconUserPlus } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { ActionInfoContext } from '../../providers/action-info.provider';
import { syncCustomerUsers } from '../../services/customers.service';
import { NotificationActionIcon } from '../common/notification-action-icon.component';
import { FilterInput } from '../common/filter-input.component';
import { UserAddModal } from '../users/user-add-modal.component';
import { getCustomerUsersReport } from '../../services/report.service';
import { downloadExcelFile } from '../../utils/download-excel-file';

export const CustomerUsersToolbar = ({
  isLoadingCustomerUsers,
  customerUsers,
  setCustomerUsers,
  filteredCustomerUsers,
  searchCustomerUserValue,
  setSearchCustomerUserValue,
  setAddCustomerUserMode,
}) => {
  const { selectedCustomer, customers } = useContext(AppContext);
  const { setCustomerActionInfo } = useContext(ActionInfoContext);
  
  const queryClient = useQueryClient();
  
  const companyName = selectedCustomer?.companyName || '';

  return (
    <Group p="xs" position="apart" style={{ backgroundColor: '#f6f6f6',  borderBottom: '1px solid #dee2e6'}}>
      <Group>
        <FilterInput
          setFilterTerm={setSearchCustomerUserValue}
          placeholder="Filter by username or email"
          filterTerm={searchCustomerUserValue}
          disabled={isLoadingCustomerUsers || !selectedCustomer?.id || !customerUsers?.length}
          width="260px"
        />
        <Text size="sm" color="dark">
          {(customerUsers.length && searchCustomerUserValue) ? `${filteredCustomerUsers.length} of ` : ''}
          {Boolean(customerUsers.length) && `${customerUsers.length} users`}
        </Text>
      </Group>
      <Group>
        <NotificationActionIcon
          id={`get-customer-${selectedCustomer.id}-users-report`}
          title={`Customer "${companyName}" users report`}
          startMessage={`Generating "${companyName}" users report`}
          successMessage={() => `The ${companyName} users report was successfully generated, check your downloads`}
          failMessage={error => (error?.message || `The ${companyName} users report failed`)}
          action={async () => await getCustomerUsersReport(selectedCustomer.id)}
          disabled={isLoadingCustomerUsers || !selectedCustomer?.id}
          onSuccess={({ data }) => downloadExcelFile(`Customer Users ${companyName}` , data)}
          actionInfo={`export "${companyName}" customer users`}
          setActionInfo={setCustomerActionInfo}
        >
          <IconTableExport size={16} />
        </NotificationActionIcon>
        <NotificationActionIcon
          id={`sync-customer-${selectedCustomer.id}-users`}
          title={`Sync "${companyName}" users`}
          startMessage={`Syncing "${companyName}" users`}
          successMessage={() => `The ${companyName} users were successfully synced`}
          failMessage={error => (error?.message || `The ${companyName} users sync failed`)}
          action={async () => await syncCustomerUsers(selectedCustomer.id)}
          disabled={isLoadingCustomerUsers || !selectedCustomer?.id}
          onSuccess={() => queryClient.invalidateQueries({ queryKey: ['customerUsers', selectedCustomer.id] })}
          actionInfo={`sync "${companyName}" users`}
          setActionInfo={setCustomerActionInfo}
        >
          <IconRefresh size={16} />
        </NotificationActionIcon>
        {(customers.length > 1) &&
          <Button
            onClick={() => {
              setSearchCustomerUserValue('');
              setAddCustomerUserMode(true);
            }}
            disabled={isLoadingCustomerUsers || !selectedCustomer?.id}
            leftIcon={<IconUserPlus size={16} />}
            color="dark-blue"
          >
            Add Existing User
          </Button>
        }
        <UserAddModal
          disabled={isLoadingCustomerUsers}
          forCustomer={true}
          onUserAddSuccess={(user) => {
            setCustomerUsers(customerUsers => {
              if (!customerUsers.find(customerUser => customerUser.id == user.id) ) {
                return [user, ...customerUsers ]
              }
              return customerUsers;
            }); 
          }}
        />
      </Group>
    </Group>
  );
};
