import React, { useState, useCallback, useEffect } from 'react';
import { TextInput, ActionIcon } from '@mantine/core';
import { IconFilter, IconX } from '@tabler/icons-react';

import { debounce } from '../../utils/debounce';

export const FilterInput = ({
  placeholder,
  setFilterTerm,
  filterTerm,
  disabled = false,
  width = '220px',
}) => {
  const [filterInput, setFilterInput] = useState('');

  const optimizedSetSearchTerm = useCallback(
    debounce((filterTerm) => setFilterTerm(filterTerm)),
    []
  );

  const handleFilter = (e) => {
    const { value } = e.target;
    setFilterInput(value);
    optimizedSetSearchTerm(value);
  };

  const handleCleanFilter = () => {
    setFilterInput('');
    setFilterTerm('');
  };

  useEffect(() => {
    setFilterInput(filterTerm);
  }, [filterTerm])

  return (
    <TextInput
      placeholder={placeholder || 'Filter...'}
      value={filterInput}
      onChange={handleFilter}
      disabled={disabled}
      icon={<IconFilter size={16} />}
      style={{ width }}
      radius={0}
      rightSection={
        <ActionIcon onClick={handleCleanFilter} disabled={!Boolean(filterInput)}>
          <IconX size={16}/>
        </ActionIcon>
      }
    />
  );
};
