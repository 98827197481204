import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Modal, Button, Group, TextInput, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';


import { patchCustomer } from '../../services/customers.service';

import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';

export const CustomerEditModal = ({ customer, show, onClose, onSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const form = useForm({
    initialValues: {
      companyName: '',
      address: '',
    },
  });

  const editCustomer = useMutation(patchCustomer);

  const onSubmit = (values) => {
    setSubmitting(true);
    const editedCustomer = { 
      ...customer,
      ...values,
    };

    editCustomer.mutate(editedCustomer, {
      onSuccess: () => {
        showSuccessNotification({
          message: `The customer "${editedCustomer.companyName}" was successfully updated`,
        });
        form.reset();
        onSuccess(editedCustomer);
      },
      onError: () => {
        showErrorNotification({
          message: `The customer "${customer.companyName}" was not updated`,
        });
      },
      onSettled: () => {
        onClose();
        setSubmitting(false);
      },
    });
  };

  useEffect(() => {
    if (customer?.id) {
      form.setValues({
        companyName: customer.companyName,
        address: customer.address || '',
      });
    }
  }, [customer])

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={onClose}
      title="Edit Customer"
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <TextInput
            required
            placeholder="company name"
            label="Company name"
            {...form.getInputProps("companyName")}
          />
          <TextInput
            placeholder="www.example.com"
            label="Web address"
            {...form.getInputProps("address")}
          />
        </Stack>
        <Group mt="md" position="right">
          <Button
            type="submit"
            loading={submitting}
          >
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
