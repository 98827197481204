import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useViewportSize } from '@mantine/hooks';
import { LoadingOverlay, Paper, Stack, Text } from '@mantine/core';
import { IconUserSearch } from '@tabler/icons-react';
import DataTable from 'react-data-table-component';

import { AppContext } from '../../providers/app-provider';
import { getCustomerBrandUsers, getCustomerUsers } from '../../services/customers.service';
import { BrandUsersToolbar } from './brand-users-toolbar.component';
import { BrandAddUserToolbar } from './brand-add-user-toolbar.component';
import { BrandUsersTableActions } from './brand-users-table-actions.component';
import { sortBy } from '../../utils/sort-by';

const paginationOptions = {
  noRowsPerPage: true,
};

export const BrandUsersTable = ({ isInView }) => {
  const { selectedBrand, selectedCustomer } = useContext(AppContext);
  const [brandUsers, setBrandUsers] = useState([]);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [searchBrandUserValue, setSearchBrandUserValue] = useState('');
  const [addBrandUserMode, setAddBrandUserMode] = useState(false);
  const { height } = useViewportSize();

  const { isLoading: isLoadingBrandUsers, data: brandUsersData } = useQuery(
    ['brandUsers', selectedCustomer.id, selectedBrand.id],
    getCustomerBrandUsers,
    {
      enabled: isInView && Boolean(selectedCustomer?.id) && Boolean(selectedBrand?.id),
    }
  );

  useEffect(() => {
    setBrandUsers((brandUsersData?.data || []));
  }, [brandUsersData]);

  const { data: customerUsersData } = useQuery(
    ['customerUsers', selectedCustomer.id],
    getCustomerUsers,
    {
      enabled: Boolean(selectedCustomer?.id),
    }
  );

  useEffect(() => {
    setCustomerUsers(customerUsersData?.data || []);
  }, [customerUsersData]);

  const filteredBrandUsers = useMemo(() => {
    let filteredUsers = [...brandUsers];
    if (customerUsers?.length) {
      filteredUsers = filteredUsers.map(user => ({
        ...user,
        role: customerUsers.filter(u => u.id == user.id)[0]['role'],
      }))
    }
    
    if (searchBrandUserValue) {
      return filteredUsers.filter((user) =>
        (
          (user.userName || '')
            .toLocaleLowerCase()
            .includes(searchBrandUserValue.toLocaleLowerCase()) ||
          (user.email || '')
            .toLocaleLowerCase()
            .includes(searchBrandUserValue.toLocaleLowerCase())
        )
      );
    }
    return filteredUsers;
  }, [brandUsers, searchBrandUserValue, customerUsers]);


  const brandActionsCell = useCallback(
    (user) => <BrandUsersTableActions user={user} setUsers={setBrandUsers} />,
    [setBrandUsers],
  );

  const brandUserColumns = useMemo(
    () => ([
      {
        name: 'userId',
        selector: (user) => user.id,
        omit: true,
      },
      {
        name: 'Username',
        selector: (user) => user.userName,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'userName'),
        grow: 1,
      },
      {
        name: 'E-mail',
        selector: (user) => user.email,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'email'),
        grow: 1,
      },
      {
        name: 'Display Name',
        selector: (user) => user.displayName,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'displayName'),
        width: '200px',
      },
      {
        name: 'Role',
        selector: (user) => user.role,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'role'),
        width: '100px',
      },
      {
        name: 'Actions',
        center: true,
        width: '180px',
        cell: brandActionsCell,
      },
    ]),
    [sortBy, brandActionsCell],
  );

  const fixedHeaderScrollHeight = useMemo(() => `${height-350}px`, [height]);

  const customStyles = useMemo(
    () => ({
      table: { style: { maxHeight: `${height-408}px` }},
    }),
    [height],
  );
  const containerStyles = useMemo(
    () => ({
      height: `${height-348}px`,
      width: 'calc(100vw - 504px)',
      border: '1px solid #dee2e6',
      position: 'relative'
    }),
    [height],
  );

  const noDataComponent = useMemo(
    () => ((selectedBrand?.id && isLoadingBrandUsers) ? '' : (
      <Paper p="xs">
        {(selectedBrand?.id && !brandUsers.length) ?
          <Stack align="center" mt="sm">
            <IconUserSearch size={32} color="gray" />
            <Text size="sm" color="gray"><em>This brand has no users</em></Text>
          </Stack> : 
          <Text size="sm" color="gray">
            <em>{searchBrandUserValue ? 'No users found' : 'Select a brand'}</em>
          </Text>
        }
      </Paper>
    )),
    [selectedBrand, isLoadingBrandUsers, brandUsers, searchBrandUserValue],
  );

  return (
    <Stack spacing={0}>
      {addBrandUserMode ?
        <BrandAddUserToolbar
          brandUsers={brandUsers}
          customerUsers={customerUsers}
          setBrandUsers={setBrandUsers}
          setAddBrandUserMode={setAddBrandUserMode}
        /> :
        <BrandUsersToolbar
          isLoadingBrandUsers={isLoadingBrandUsers}
          brandUsers={brandUsers}
          searchBrandUserValue={searchBrandUserValue}
          filteredBrandUsers={filteredBrandUsers}
          setSearchBrandUserValue={setSearchBrandUserValue}
          setAddBrandUserMode={setAddBrandUserMode}
        />
      }
      <Paper m="xs" style={containerStyles}>
        <LoadingOverlay visible={selectedBrand?.id && isLoadingBrandUsers}></LoadingOverlay>
        <DataTable
          columns={brandUserColumns}
          data={filteredBrandUsers}
          highlightOnHover
          // pointerOnHover
          selectableRowsSingle
          dense
          fixedHeader
          fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          pagination
          paginationPerPage={20}
          paginationComponentOptions={paginationOptions}
          customStyles={customStyles}
          noDataComponent={noDataComponent}
        />
      </Paper>
    </Stack>
  );
};
