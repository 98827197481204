import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Button, Group, Container, Paper, LoadingOverlay, Text, Tooltip } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { hideNotification, showNotification } from '@mantine/notifications';
import {  IconCheck, IconEdit, IconLock, IconLogin, IconRefresh, IconTableExport, IconTrash, IconUser, IconX } from '@tabler/icons-react';
import DataTable from 'react-data-table-component';

import { NotificationsContext } from '../../providers/notifications.provider';
import { getUsers, syncUser } from '../../services/users.service';
import { FilterInput } from '../common/filter-input.component';
import { UserEditModal } from './user-edit-modal.component';
import { UserDeleteModal } from './user-delete-modal.component';
import { UserResetPasswordModal } from './user-reset-password-modal.component';
import { UserAddModal } from './user-add-modal.component';
import { setFailNotification, setStartNotification, setSuccessNotification, showErrorNotification } from '../../utils/notifications';
import { getCustomerUsersReport, getLogonAttemptsReport } from '../../services/report.service';
import { downloadExcelFile } from '../../utils/download-excel-file';

const paginationRowsPerPageOptions = [25, 50, 100, 200];

const paginationOptions = {
  noRowsPerPage: true,
};

export const UsersPage = () => {
  const { addNotification, deleteNotification, notifications } = useContext(NotificationsContext);
  const [exportingLogonAttempts, setExportingLogonAttempts] = useState(false);
  const [exportingCustomerUsers, setExportingCustomerUsers] = useState(false);
  const { height } = useViewportSize();
  const queryClient = useQueryClient();
  const [data, setData] = useState([]);
  const [isSyncingUser, setIsSyncingUser] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [orderQuery, setOrderQuery] = useState('');

  const { data: usersResponse, isLoading } = useQuery(
    ['users', searchTerm, rowsPerPage, page, orderQuery],
    getUsers,
    {
      onError: () => {
        setData([]);
      },
    }
  );

  const syncUserCustomers = async (user) => {
    setIsSyncingUser(true);
    setActionUser(user);
    try {
      const { data } = await syncUser(user.id);
      const syncResults = [];
      for (const key in data) {
        syncResults.push({
          key,
          value: data[key],
        });
      }
      const successCount = syncResults.filter(item => item.value == 'Success').length;
      const message = (
        <div style={{ maxHeight: '140px', overflowY: 'scroll'}}>
          {syncResults.map(({ key, value }) => (
            <div key={key} style={{ marginBottom: '5px' }}>
              <Group spacing={4}>
                {value == 'Success' ? <IconCheck size={14} color="teal" /> : <IconX size={14} color="red" />}
                <Text weight={600} color="dark" size="sm" style={{ alignItems: 'center', lineHeight: '1' }}>
                  {key}
                </Text>
              </Group>
              <Text color="dimmed" size="xs" ml={18}>{value}</Text>
            </div>
          ))}
        </div>
      );
      showNotification({
        color: successCount == syncResults.length ? 'teal' : successCount == 0 ? 'red' : 'blue',
        title: <Group spacing="xs" mb="sm"><IconRefresh size={20} /><Text weight={600}>{user.userName}</Text><Text color="dimmed">Sync Results</Text></Group>,
        message,
        autoClose: false
      })
    } catch (e) {
      console.error(e);
      showErrorNotification({
        message: e?.message || 'Cannot sync users data',
      });
    } finally {
      setIsSyncingUser(false);
      setActionUser({});
    }
  };
  
  const exportLogonNotificationId = `export-logon-users`;

  const handleExportLogonAttempts = useCallback(
    async (userName = '') => {
      const id = `${exportLogonNotificationId}${userName}`
      addNotification(id);
      try {
        hideNotification(id);
        setStartNotification({
          id: id,
          title: 'Logon attempts report',
          message: `Generating ${userName ? `"${userName}"` : 'all users'} logon attempts report`,
        });

        const { data } = await getLogonAttemptsReport(userName);
        downloadExcelFile(`Logon Attempts for ${userName ? `${userName}` : 'All Users'}`, data);
        
        setSuccessNotification({
          id: id,
          title: 'Logon attempts report',
          message: `Report ${userName ? `for "${userName}"` : 'all users'} logon attempts successfully generated, check your downloads`,
          autoClose: false,
        });
      } catch (error) {
        setFailNotification({
          id: id,
          title: 'Logon attempts report',
          message: `Report generation ${userName ? `for "${userName}"` : 'all users'} logon attempts failed`,
        });
      } finally {
        setExportingLogonAttempts(false);
        deleteNotification(id);
      }
    },
    [
      addNotification,
      hideNotification,
      deleteNotification,
      downloadExcelFile,
      setFailNotification,
      setStartNotification,
      setSuccessNotification,
      setExportingLogonAttempts,
      exportLogonNotificationId,
    ],
  );
  
  const exportCustomerUsersNotificationId = `export-customer-users`;

  const handleExportCustomerUsers = useCallback(
    async () => {
      const id = exportCustomerUsersNotificationId;
      addNotification(id);
      try {
        hideNotification(id);
        setStartNotification({
          id: id,
          title: 'Customer users report',
          message: `Generating customer users report`,
        });
        const { data } = await getCustomerUsersReport();
        downloadExcelFile('Customer Users', data);
        setSuccessNotification({
          id: id,
          title: 'Customer users report',
          message: `Report successfully generated, check your downloads`,
          autoClose: false,
        });
      } catch (error) {
        setFailNotification({
          id: id,
          title: 'Customer users report',
          message: `Report generation failed`,
        });
      } finally {
        setExportingCustomerUsers(false);
        deleteNotification(id);
      }
    },
    [
      addNotification,
      hideNotification,
      deleteNotification,
      setFailNotification,
      setStartNotification,
      setSuccessNotification,
      setExportingCustomerUsers,
      exportCustomerUsersNotificationId,
    ],
  );

  useEffect(() => {
    if (notifications[exportLogonNotificationId]) {
      setExportingLogonAttempts(true);
    } else {
      setExportingLogonAttempts(false);
    }
  }), [notifications, exportLogonNotificationId, setExportingLogonAttempts];

  useEffect(() => {
    if (notifications[exportCustomerUsersNotificationId]) {
      setExportingCustomerUsers(true);
    } else {
      setExportingCustomerUsers(false);
    }
  }), [notifications, exportCustomerUsersNotificationId, setExportingCustomerUsers];

  useEffect(() => {
    if (usersResponse?.data) {
      const { users, pagination } = usersResponse.data;
      setData(users);
      setTotalRows(pagination.totalCount);
    }
  }, [usersResponse]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const [editUserModal, setEditUserModal] = useState(false);
  const [removeUserModal, setDeleteUserModal] = useState(false);
  const [resetPasswordUserModal, setResetPasswordUserModal] = useState(false);
  const [actionUser, setActionUser] = useState({});

  const handleAction = useCallback(
    (user, action) => {
      setActionUser(user);
      action(true);
    },
    [ setActionUser],
  );

  const userActionsCell = useCallback(
    (user) => (
      <Group spacing={0}>
        <Tooltip label="Edit user" withArrow placement="end">
          <ActionIcon
            onClick={() => handleAction(user, setEditUserModal)}
            color="dark"
            radius="xl"
          >
            <IconEdit size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Reset user password" withArrow placement="end">
          <ActionIcon
            onClick={() => handleAction(user, setResetPasswordUserModal)}
            color="dark"
            radius="xl"
          >
            <IconLock size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Sync user apps" withArrow placement="end">
          <ActionIcon
            onClick={() => syncUserCustomers(user)} loading={isSyncingUser && actionUser.id == user.id}
            color="dark"
            radius="xl"
          >
            <IconRefresh size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Logon attempts report" withArrow placement="end">
          <ActionIcon
            onClick={() => handleExportLogonAttempts(user.userName)}
            color="dark"
            radius="xl"
          >
            <IconLogin size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete user" withArrow placement="end">
          <ActionIcon
            onClick={() => handleAction(user, setDeleteUserModal)}
            color="red"
            radius="xl"
          >
            <IconTrash size={16} />
          </ActionIcon>
        </Tooltip>
      </Group>
    ),
    [
      actionUser,
      handleAction,
      syncUserCustomers,
      handleExportLogonAttempts,
      setEditUserModal,
      setResetPasswordUserModal,
      setDeleteUserModal,
      isSyncingUser,
    ],
  );

  const columns = useMemo(
    () => ([
      {
        name: 'userId',
        selector: row => row.id,
        omit: true,
      },
      {
        name: 'Username',
        selector: row => row.userName,
        sortable: true,
        sortfield: 'UserName',
        grow: 1,
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        sortfield: 'Email',
        grow: 1,
      },
      {
        name: 'Display Name',
        selector: row => row.displayName,
        sortable: true,
        sortfield: 'DisplayName',
        width: '200px',
      },
      {
        name: 'Role',
        width: '100px',
        selector: row => row.role,
        sortable: true,
        sortfield: 'Role',
      },
      {
        name: 'Actions',
        center: true,
        width: '180px',
        cell: userActionsCell,
      },
    ]),
    [
      userActionsCell,
    ],
  );

  const fixedHeaderScrollHeight = useMemo(
    () => `${height-126}px`,
    [height]
  );

  const handleSort = useCallback(
    (column, sortDirection) => setOrderQuery(`${column.sortfield} ${sortDirection}`),
    [setOrderQuery],
  );

  const onChangeRowsPerPage = useCallback(
    (rowsPerPage, page) => {
      setRowsPerPage(rowsPerPage);
      setPage(page);
    },
    [setRowsPerPage, setPage],
  );

  const onChangePage = useCallback(
    (page) => setPage(page),
    [setPage],
  );

  const onUserAdd = useCallback(
    (user) => {
      if (user?.userName) setSearchTerm(user.userName);
    },
    [setSearchTerm],
  );

  const onUserEdit = useCallback(
    (editedUser) => {
      setActionUser(actionUser => {
        if (editedUser.id == actionUser.id) {
          setEditUserModal(false);
        }
        return actionUser;
      });
      setData(data => {
        return data.map(user => {
          if (user.id == editedUser.id) {
            return { ...editedUser };
          }
          return user;
        });
      });
      queryClient.invalidateQueries({ queryKey: ['users', searchTerm, rowsPerPage, page, orderQuery] });
    },
    [setActionUser, setEditUserModal, setData, queryClient, searchTerm, rowsPerPage, page, orderQuery],
  );

  const onUserDelete = useCallback(
    (deletedUser) => {
      setActionUser(actionUser => {
        if (deletedUser.id == actionUser.id) {
          setDeleteUserModal(false);
        }
        return actionUser;
      });
      setData(data => {
        return data.filter(user => user.id !== deletedUser.id);
      });
      queryClient.invalidateQueries({ queryKey: ['users', searchTerm, rowsPerPage, page, orderQuery] });
    },
    [setActionUser, setDeleteUserModal, setData, queryClient, searchTerm, rowsPerPage, page, orderQuery],
  );

  return (
    <Container fluid p={0}>
      <Group mb="xs" position="apart">
        <FilterInput
          placeholder="Filter by username or email"
          setFilterTerm={setSearchTerm}
          filterTerm={searchTerm}
          width="260px"
        />
        <Group>
          <Button
            onClick={handleExportCustomerUsers} 
            rightIcon={<IconTableExport size={16} />}
            loading={exportingCustomerUsers}
          >
            Export customer users
          </Button>
          <Button
            onClick={handleExportLogonAttempts} 
            rightIcon={<IconTableExport size={16} />}
            loading={exportingLogonAttempts}
          >
            Export logon attempts
          </Button>
          <UserAddModal onUserAddSuccess={onUserAdd}/>
        </Group>
      </Group>
      <Paper withBorder radius={0} style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          paginationTotalRows={totalRows}
          paginationComponentOptions={paginationOptions}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          sortServer
          onSort={handleSort}
          highlightOnHover
          pointerOnHover
          selectableRowsSingle
          fixedHeader
          fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          dense
        />
      </Paper>
      <UserEditModal
        user={actionUser}
        show={editUserModal}
        closeModal={() => setEditUserModal(false)}
        onAccept={onUserEdit}
      />
      <UserDeleteModal
        user={actionUser}
        show={removeUserModal}
        closeModal={() => setDeleteUserModal(false)}
        onAccept={onUserDelete}
      />
      <UserResetPasswordModal
        user={actionUser}
        show={resetPasswordUserModal}
        closeModal={() => setResetPasswordUserModal(false)}
      />
    </Container>
  );
};
