import { openConfirmModal  } from '@mantine/modals';
import { Text } from '@mantine/core';

export const openDeletionModal = ({ title, message, label, onConfirm }) => openConfirmModal({
  title,
  children: <Text size="sm">{message}</Text>,
  cancelProps: { style: { display: 'none' }},
  confirmProps: { color: 'red.7' },
  labels: { confirm: label || 'Delete' },
  onConfirm: onConfirm,
});

export const openConfirmationModal = ({ title, message, label, onConfirm }) => openConfirmModal({
  title,
  children: <Text size="sm">{message}</Text>,
  cancelProps: { style: { display: 'none' }},
  labels: { confirm: label || 'Confirm' },
  onConfirm: onConfirm,
});
