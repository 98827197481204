import { useCallback, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Group } from '@mantine/core';
import { IconUserX } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { ActionInfoContext } from '../../providers/action-info.provider';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';
import { UserCommonActions } from '../users/user-common-actions.component';
import { CustomerUserRemoveModal } from './customer-user-remove-modal.component';

export const CustomerUserTableActions = ({ user, setCustomerUsers }) => {
  const { selectedCustomer } = useContext(AppContext);
  const { setCustomerActionInfo } = useContext(ActionInfoContext);

  const [removeCustomerUserModal, setRemoveCustomerUserModal] = useState(false);

  const queryClient = useQueryClient();

  const refetchCustomerUsers = () => queryClient.invalidateQueries({
     queryKey: ['customerUsers', selectedCustomer.id],
  });

  const handleCustomerUserRemovalSuccess = useCallback((customer, deletedUser) => {
    if (deletedUser.id == user.id) {
      setRemoveCustomerUserModal(false);
    }
    setCustomerUsers(users => {
      if(selectedCustomer.id == customer.id) {
        return users.filter(user => user.id !== deletedUser.id);
      }
      return users;
    });
    refetchCustomerUsers();
  }, [selectedCustomer, setCustomerUsers, setRemoveCustomerUserModal]);

  return (
    <Group spacing={0}>
      <UserCommonActions
        user={user}
        setUsers={setCustomerUsers}
        customer={selectedCustomer}
        onEdit={refetchCustomerUsers}
        setActionInfo={setCustomerActionInfo}
      />
      <ActionIconWithInfo
        color="red"
        actionInfo={`remove "${user.userName}" from "${selectedCustomer.companyName}"`}
        setActionInfo={setCustomerActionInfo}
        onClick={() =>  setRemoveCustomerUserModal(true)}
      >
        <IconUserX size={16}/>
      </ActionIconWithInfo>
      <CustomerUserRemoveModal
        user={user}
        customer={selectedCustomer}
        show={removeCustomerUserModal}
        onClose={() => setRemoveCustomerUserModal(false)}
        onSuccess={handleCustomerUserRemovalSuccess}
      />
    </Group>
  );
};
