import { Space, Text } from '@mantine/core';
import { IconClick } from '@tabler/icons-react';

export const InfoText = ({ children }) => (
  <Text
    fs="italic"
    color="gray"
    size="sm"
    mx="md"
    style={{ display: 'flex', alignItems: 'center' }}
  >
    {children &&
      <>
        <IconClick size={18} />
        <Space w={6} />
        {children}
      </>
    }
  </Text>
);
