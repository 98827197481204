import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppShell } from '@mantine/core';

import { AuthContext } from '../providers/auth-provider';
import { AppProvider } from '../providers/app-provider';
import { AppNavbar } from '../layout/app-navbar';
import { UsersPage } from './users/users-page.component';
import { CustomersPage } from './customers/customers-page.component';
import { ActionInfoProvider } from '../providers/action-info.provider';
import { NotificationsProvider } from '../providers/notifications.provider';

const appShellCssStyles = { height: '100vh', overflow: 'hidden' };
const appShellThemeStyles = { main: { padding: '10px', overflow: 'hidden', marginLeft: '220px' }};

export const App = () => {
  const { user } = useContext(AuthContext);

  if (!user && !JSON.parse(localStorage.getItem('user'))) {
    return <Navigate replace to='/login' />;
  }

  return (
    <AppProvider>
      <ActionInfoProvider>
        <NotificationsProvider>
          <AppShell
            navbar={<AppNavbar />}
            style={appShellCssStyles}
            styles={appShellThemeStyles}
            >
            <Routes>
              <Route path='customers' element={<CustomersPage />} />
              <Route path='users' element={<UsersPage />} />
            </Routes>
          </AppShell>
        </NotificationsProvider>
      </ActionInfoProvider>
    </AppProvider>
  );  
};
