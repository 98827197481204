import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Button, Group, TextInput, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconTag } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { postCustomerBrand } from '../../services/customers.service';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';

export const BrandAddModal = ({ setActiveTab }) => {
  const {
    customers,
    isLoadingCustomers,
    selectedCustomer,
    setSelectedBrand,
  } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const queryClient = useQueryClient();

  const brandNameRef = useRef(null);

  const form = useForm({
    initialValues: {
      customerId: '',
      brandName: '',
    },
  });

  const addBrand = useMutation(postCustomerBrand);

  const selectCustomers = useMemo(
    () => customers.map((customer) => ({ value: customer.id.toString(), label: customer.companyName })),
    [customers],
  );

  const onSubmit = (values) => {
    setSubmitting(true);
    addBrand.mutate(values, {
      onSuccess: ({ data: brand }) => {
        showSuccessNotification({
          message: `The brand "${values.brandName}" was successfully created`,
        });
        form.reset();
        queryClient.invalidateQueries({ queryKey: ['brands'] });
        setSelectedBrand(brand);
        setActiveTab('brands');
      },
      onError: () => {
        showErrorNotification({
          message: `The brand "${values.brandName}" was not created`,
        });
      },
      onSettled: () => {
        setSubmitting(false);
      },
    });
  };

  useEffect(() => {
    if (opened && selectedCustomer?.id) {
      form.setFieldValue("customerId", selectedCustomer.id.toString());
    }
  }, [selectedCustomer, opened]);
  
  useEffect(() => {
    if (opened) {
      setTimeout(() => brandNameRef?.current?.focus(), 100);
    }
  }, [brandNameRef, opened]);

  return (
    <>            
      <Button onClick={open} leftIcon={<IconTag size={16} />}>
        Create Brand
      </Button>
      <Modal
        size="md"
        zIndex="500"
        opened={opened}
        onClose={close}
        title="Add a New Brand"
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack spacing="lg">
            <Stack>
              <Select
                zIndex="600"
                data={selectCustomers}
                label="Customer"
                placeholder="Customer"
                searchable
                required
                disabled={isLoadingCustomers}
                {...form.getInputProps('customerId')}
              />
              <TextInput
                ref={brandNameRef}
                required
                placeholder="Brand Name"
                label="Brand Name"
                {...form.getInputProps('brandName')}
              />
            </Stack>
            <Group position="right">
              <Button
                type="submit"
                loading={submitting}
                disabled={isLoadingCustomers}
              >
                Save
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
