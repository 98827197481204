import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ActionIcon, Autocomplete, Button, Group } from '@mantine/core';
import { IconUser, IconUserPlus, IconX } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';
import { postCustomerBrandUser } from '../../services/customers.service';

export const BrandAddUserToolbar = ({
  brandUsers,
  customerUsers,
  setBrandUsers,
  setAddBrandUserMode,
}) => {
  const { selectedBrand, selectedCustomer, setSelectedBrand } = useContext(AppContext);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [addingUserToBrand, setAddingUserToBrand] = useState(false);

  const queryClient = useQueryClient();

  const postCustomerBrandUserMutation = useMutation({
    mutationFn: postCustomerBrandUser,
    onSuccess: (_, variables) => {
      const { customer, brand, user } = variables;
      const id = `adding-customer-brand-user-${customer.id}-${brand.id}-${user.id}`;
      setSuccessNotification({
        id,
        title: 'Adding User to Brand',
        message: `The user "${user.userName}" was added to brand "${brand.brandName}" successfully`,
      });
      queryClient.invalidateQueries({ queryKey: ['brandUsers', customer.id, brand.id] });
      setSelectedBrand(selectedBrand => {
        if (selectedBrand.id == brand.id) {
          setBrandUsers(brandUsers => {
            if (!brandUsers.find(customerBrandUser => customerBrandUser.id == user.id) ) {
              return [user, ...brandUsers ]
            }
            return brandUsers;
          });    
        }
        return selectedBrand;
      })
    },
    onError: () => {
      setFailNotification({
        id,
        title: 'Adding User to Brand',
        message: `The user "${user.userName}" was not added to brand "${selectedBrand.brandName}"`,
      });
    },
    onSettled: () => {
      setSearchUserValue('');
      setAddingUserToBrand(false);
    },
  });

  const handleAutocompleteChange = (value) => {
    setSearchUserValue(value);
  };

  const handleAddUser = async () => {
    let user = customerUsers.filter(user => user.userName === searchUserValue)[0];
    setAddingUserToBrand(true);
    const notificationKey = `adding-customer-brand-user-${selectedCustomer.id}-${selectedBrand.id}-${user.id}`;
    setStartNotification({
      id: notificationKey,
      title: 'Adding User to Brand',
      message: `Adding user "${user.userName}" to brand "${selectedBrand.brandName}"`,
    });
    postCustomerBrandUserMutation.mutate({customer: selectedCustomer, brand: selectedBrand, user});          
  };

  return (
    <Group p="xs" m={0} position="apart" style={{ backgroundColor: '#5d71a9', borderBottom: '1px solid #5d71a9' }}>
      <Group>
        <Autocomplete
          autoFocus
          disabled={addingUserToBrand}
          value={searchUserValue}
          placeholder={`Search for ${selectedCustomer?.id ? `"${selectedCustomer.companyName}"` : ''} users`}
          data={searchUserValue
            ? customerUsers
              .map(user => user.userName)
              .filter(userName => !brandUsers.map(user => user.userName).includes(userName))
            : []
          }
          icon={<IconUser />}
          variant="unstyled"
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '320px'}}
          limit={20}
          onChange={handleAutocompleteChange}
          rightSection={
            <ActionIcon onClick={() => setSearchUserValue('')}>
              <IconX size={16}/>
            </ActionIcon>
          }
        />
        <Button
          variant="white"
          disabled={!(customerUsers.map(user => user.userName).includes(searchUserValue))}
          loading={addingUserToBrand}
          onClick={handleAddUser}
          leftIcon={<IconUserPlus size={16} />}
        >
          Add User to Brand
        </Button>
      </Group>
      <Button
        variant="white"
        leftIcon={<IconX size={16} />}
        onClick={() => { setAddBrandUserMode(false); setSearchUserValue(''); }}
        disabled={addingUserToBrand}
      >
        Close
      </Button>
    </Group>
  );
};
