import { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Anchor, Group, Space, Text } from '@mantine/core';
import { IconEdit, IconTrash, IconRefreshDot, IconExternalLink } from '@tabler/icons-react';

import { AuthContext } from '../../providers/auth-provider';
import { AppContext } from '../../providers/app-provider';
import { ActionInfoContext } from '../../providers/action-info.provider';
import { testSync } from '../../services/account.service';
import { CustomerEditModal } from './customer-edit-modal.component';
import { CustomerDeleteModal } from './customer-delete-modal.component';
import { NotificationActionIcon } from '../common/notification-action-icon.component';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';

export const CustomerToolbar = () => {
  const { isAdmin } = useContext(AuthContext);
  const { selectedCustomer, setSelectedCustomer } = useContext(AppContext);
  const { setCustomerActionInfo } = useContext(ActionInfoContext);

  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false);

  const companyName = selectedCustomer?.companyName || '';
  const companyWebUrl = selectedCustomer?.address || '';

  const queryClient = useQueryClient();

  const onActionSuccess = (customer) => {
    queryClient.invalidateQueries({ queryKey: ['customers'] });
    setSelectedCustomer(customer);
  };

  return (
    <Group
      p="xs"
      pl="md"
      position="apart"
      style={{ backgroundColor: '#f6f6f6', minHeight: '49px', borderBottom: '1px solid #dee2e6'}}
    >
      {companyName ?
        <Group spacing="xs" style={{ flexGrow: '1' }}>
          <Text size="xs" color="gray">CUSTOMER</Text>
          <Text size="lg" weight={500}>{companyName}</Text>
          <Text size="xs" color="gray">ID {selectedCustomer.id}</Text>
          {companyWebUrl &&
          <>
            <Space w={10} />
            <Anchor
              href={companyWebUrl}
              target="_blank"
              color="dark-blue.8"
              size="sm"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {companyWebUrl}
              <Space w={2} />
              <IconExternalLink size={16} />
            </Anchor>
          </>
          }
        </Group> :
        <Group spacing="xs" style={{ flexGrow: '1' }}>
          <Text size="sm" color="gray"><em>Select a customer</em></Text>
        </Group>
      }
      <Group spacing="xs">
        <Space w={10} />
        <NotificationActionIcon
          id={`test-sync-customer-${selectedCustomer.id}`}
          title={`Test "${companyName}" sync connection`}
          startMessage={`Testing "${companyName}" sync connection`}
          successMessage={() => `The ${companyName} sync service is up`}
          failMessage={error => (error?.message || `The ${companyName} sync connection is not working`)}
          action={async () => await testSync(selectedCustomer)}
          disabled={!selectedCustomer?.id}
          color="dark-blue"
          variant="filled"
          actionInfo="TEST customer sync connection"
          setActionInfo={setCustomerActionInfo}
        >
          <IconRefreshDot size={18} />
        </NotificationActionIcon>
        {isAdmin &&
          <>
            <Space w={32} />
            <ActionIconWithInfo
              disabled={!(selectedCustomer?.id)}
              actionInfo="edit customer info"
              setActionInfo={setCustomerActionInfo}
              onClick={() => setEditCustomerModal(true)}
            >
              <IconEdit size={16} />
            </ActionIconWithInfo>
            <ActionIconWithInfo
              disabled={!(selectedCustomer?.id)}
              actionInfo="delete customer"
              setActionInfo={setCustomerActionInfo}
              onClick={() => setDeleteCustomerModal(true)}
            >
              <IconTrash size={16} />
            </ActionIconWithInfo>
          </>
        }
      </Group>
      <CustomerEditModal
        customer={selectedCustomer}
        show={editCustomerModal}
        onClose={() => setEditCustomerModal(false)}
        onSuccess={onActionSuccess}
      />
      <CustomerDeleteModal
        customer={selectedCustomer}
        show={deleteCustomerModal}
        onClose={() => setDeleteCustomerModal(false)}
        onSuccess={onActionSuccess}
      />
    </Group>
  );
};
