import { useContext, useEffect, useState } from 'react';
import { createStyles, Avatar, Navbar, Group, Stack, Code, Text, getStylesRef, rem, Badge } from '@mantine/core';
import {
  IconBuilding,
  IconLogout,
  IconShieldHalfFilled,
  IconUsers,
} from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../providers/auth-provider';

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colors[theme.primaryColor][8],
  },

  version: {
    backgroundColor: theme.colors[theme.primaryColor][7],
    color: theme.white,
    fontSize: '12px',
    fontWeight: 700,
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md,
    borderBottom: `${rem(1)} solid ${theme.colors[theme.primaryColor][9]}`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.colors[theme.primaryColor][9]}`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.colors[theme.primaryColor][1],
    opacity: 0.75,
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.colors[theme.primaryColor][9],
      [`& .${getStylesRef('icon')}`]: {
        opacity: 0.9,
      },
    },
  },
}));

const data = [
  {
    label: 'Customers',
    link: '/app/customers',
    icon: IconBuilding,
  },
  {
    label: 'All Users',
    link: '/app/users',
    icon: IconUsers,
  },
];

export function AppNavbar() {
  const { isAdmin, user, logout } = useContext(AuthContext);
  const { classes, cx } = useStyles();

  const [active, setActive] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const links = data.map((item) => (
    <a
      className={cx(classes.link, { [classes.linkActive]: item.link === active })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.link);
        navigate(item.link);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  useEffect(() => {
    if (location) {
      setActive(location.pathname);
    }
  }, [location]);

  return (
    <Navbar style={{ height: '100vh', width: '220px', minWidth: '220px' }} p="md" className={classes.navbar}>
      <Navbar.Section className={classes.header} >
        <Group position="apart" spacing={0}>
          <Text color={isAdmin ? 'yellow.5' : 'gray.5'}>
            <IconShieldHalfFilled size={32} strokeWidth={1.5}/>
          </Text>
          <Stack spacing={0}>
            <Group position="apart">
              <Text weight={500} color="white" size={18}>Cerberus</Text>
              <Code className={classes.version}>v1.1.0</Code>
            </Group>
            <Text color="gray.4" size={13} align="center" >Talkoot Identity Provider</Text>
          </Stack>
        </Group>
        {isAdmin &&
          <Group position="center" mt="xs">
            <Badge color="yellow.5" size="sm" variant="outline">admin</Badge>
          </Group>
        }
      </Navbar.Section>

      <Navbar.Section grow>
        {links}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <Group spacing={7}>
          <Avatar alt={user?.preferred_username} radius="xl" />
          <Text size={13} sx={{ lineHeight: 1 }} mr={3} color="gray.4">
            {user?.preferred_username}
          </Text>
        </Group>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <a
          href="#"
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            logout();
            navigate('/login');
          }}
        >
          <IconLogout className={classes.linkIcon} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}