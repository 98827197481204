import { useCallback, useContext, useEffect, useState } from 'react';
import { hideNotification, updateNotification } from '@mantine/notifications';
import { Group, Text } from '@mantine/core';
import { IconCheck, IconRefresh, IconX } from '@tabler/icons-react';

import { NotificationsContext } from '../../providers/notifications.provider';
import { syncUser } from '../../services/users.service';
import { setFailNotification, setStartNotification } from '../../utils/notifications';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';

const ResultTitle = ({ userName }) => (
  <Group spacing="xs" mb="sm">
    <Text color="dimmed">Sync Results</Text>
    <Text weight={600}>{userName}</Text>
  </Group>
);

const SuccessMessage = ({ syncResults }) => (
  <div
    style={{
      maxHeight: '140px',
      overflowY: 'scroll',
      border: '1px solid #dee2e6',
      padding: '10px 0 0 10px',
      borderRadius: '4px',
    }}
  >
    {syncResults.map(({ key, value }) => (
      <div key={key} style={{ marginBottom: '5px' }}>
        <Group spacing={4}>
          {value == 'Success' ? <IconCheck size={14} color="teal" /> : <IconX size={14} color="red" />}
          <Text weight={600} color="dark" size="sm" style={{ alignItems: 'center', lineHeight: '1' }}>
            {key}
          </Text>
        </Group>
        <Text color="dimmed" size="xs" ml={18}>{value}</Text>
      </div>
    ))}
  </div>
);

export const UserSyncAction = ({ user, setActionInfo, customer = {} }) => {
  const { addNotification, deleteNotification, notifications } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const userName = user?.userName || '';

  const id = `sync-user-${user?.id}-${customer?.id}`;

  const handleAction = useCallback(
    async () => {
      addNotification(id);
      try {
        hideNotification(id);
        setStartNotification({
          id,
          title: `Syncing user "${userName}"`,
          message: '',
        });

        const { data } = await syncUser(user.id, customer.id);
        const syncResults = [];

        for (const key in data) {
          syncResults.push({
            key,
            value: data[key],
          });
        }

        const successCount = syncResults.filter(item => item.value == 'Success').length;

        updateNotification({
          id,
          title: <ResultTitle userName={userName} />,
          message: <SuccessMessage syncResults={syncResults} />,
          color: successCount == syncResults.length ? 'teal' : successCount == 0 ? 'red' : 'blue',
          autoClose: false,
        });
      } catch (error) {
        setFailNotification({
          id,
          title: 'Sync user',
          message: error => error?.message || `Failed to sync user "${userName}"`,
        });
      } finally {
        setLoading(false);
        deleteNotification(id);
      }
    },
    [
      addNotification,
      hideNotification,
      deleteNotification,
      updateNotification,
      setStartNotification,
      setFailNotification,
      setLoading,
      syncUser,
      userName,
      id,
    ]
  );

  useEffect(() => {
    if (notifications[id]) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }), [notifications, id, setLoading];

  return (
    <ActionIconWithInfo
      loading={loading}
      setActionInfo={setActionInfo}
      actionInfo={`sync user "${user.userName}"`}
      onClick={handleAction}
    >
      <IconRefresh size={16} />
    </ActionIconWithInfo>
  );
};
