import React from 'react';
import { Switch, Text } from '@mantine/core';

export const PrimarySwitch = ({ checked, onChange, label }) => (
  <Switch
    checked={checked}
    onChange={onChange}
    label={<Text size={13}>{label}</Text>}
    color="dark-blue"
    size="xs"
    styles={{ trackLabel: { paddingInline: 0 }}}
  />
);
