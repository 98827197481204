import { useCallback, useContext, useEffect, useMemo } from 'react';
import { LoadingOverlay, Paper, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import DataTable from 'react-data-table-component';
import { IconTagOff } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { getBrands } from '../../services/brands.service';

const getBrandId = brand => brand.id;

const getBrandName = brand => brand.brandName;

export const BrandsList = () => {
  const {
    selectedBrand,
    selectedCustomer,
    setSelectedBrand,
  } = useContext(AppContext);

  const { height } = useViewportSize();
  
  const { isLoading: isLoadingBrands, data: brands } = useQuery(
    ['brands'],
    getBrands,
  );

  const customerBrands = useMemo(
    () =>
      (!(selectedCustomer?.id) ?
        [] :
        (brands?.data || []).filter((brand) => brand.customer.id === selectedCustomer.id)
      )
      .map(brand => ({
        ...brand,
        selected: selectedBrand?.id && brand.id === selectedBrand.id
      })),
    [brands, selectedCustomer, selectedBrand],
  );

  const customerBrandColumns = useMemo(() => ([
    {
      selector: getBrandId,
      omit: true,
    },
    {
      name: 'Brand Name',
      selector: getBrandName,
    },
  ]), []);

  const conditionalRowStyles = useMemo(
    () => ([
      {
        when: (row) => row.selected,
        // style: { backgroundColor: '#ced6ec' },
        style: { backgroundColor: '#c9dfe5' },
      },
    ]),
    [],
  );

  const fixedHeaderScrollHeight = `${height-184}px`;

  const handleRowClick = useCallback(row => setSelectedBrand(row), [setSelectedBrand]);

  const noDataComponent = useMemo(
    () => isLoadingBrands ? '' : (
      <Paper p="xs">
        {(selectedCustomer?.id) ?
          <Stack align="center" mt="sm">
            <IconTagOff size={32} color="gray" />
            <Text size="sm" color="gray"><em>This customer owns no brands</em></Text>
          </Stack> : 
          <Text size="sm" color="gray">
            <em>Select a customer</em>
          </Text>
        }
      </Paper>
    ),
    [selectedCustomer],
  );

  useEffect(() => {
    if (customerBrands && customerBrands.length > 0 && !selectedBrand?.id) {
      setSelectedBrand(customerBrands[0]);
    }
  }, [customerBrands, selectedBrand, setSelectedBrand]);

  return (
    <Paper
      radius={0}
      style={{
        display: 'flex',
        width: '220px',
        height: `${height-182}px`,
        borderRight: '1px solid #dee2e6',
        position: 'relative',
      }}
    >
      <LoadingOverlay visible={(isLoadingBrands)}></LoadingOverlay>
      <DataTable
        columns={customerBrandColumns}
        data={customerBrands}
        highlightOnHover
        pointerOnHover
        selectableRowsSingle
        onRowClicked={handleRowClick}
        conditionalRowStyles={conditionalRowStyles}
        dense
        noHeader
        noTableHead
        fixedHeader
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        noDataComponent={noDataComponent}
      />
    </Paper>
  );
};
