import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Modal, Button, Text, Group, Stack } from '@mantine/core';
import { IconAlertCircle, IconInfoCircle } from '@tabler/icons-react';

import { NotificationsContext } from '../../providers/notifications.provider';
import { deleteCustomerUser } from '../../services/customers.service';
import { Bold } from '../common/bold.component';
import { setStartNotification, setSuccessNotification, setFailNotification } from '../../utils/notifications';

export const CustomerUserRemoveModal = ({ user, customer, show, onClose, onSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const { notifications, addNotification, deleteNotification } = useContext(NotificationsContext);

  const notificationKey = `remove-customer-user-${customer.id}-${user.id}`;
  
  const deleteCustomerUserMutation = useMutation({
    mutationFn: deleteCustomerUser,
    onSuccess: () => {
      setSuccessNotification({
        id: notificationKey,
        title: 'Removing User from Customer',
        message: `The user "${user.userName}" was removed from "${customer.companyName}"`,
      });
      onSuccess(customer, user);
    },
    onError: () => {
      setFailNotification({
        id: notificationKey,
        title: 'Removing User from Customer',
        message: `The user "${user.userName}" was not removed from "${customer.companyName}"`,
      });
    },
    onSettled: () => {
      deleteNotification(notificationKey);
    },
  });
  
  const handleDelete = () => {
    setSubmitting(true);
    addNotification(notificationKey);
    setStartNotification({
      id: notificationKey,
      title: 'Removing User from Customer...',
      message: `Removing user "${user.userName}" from "${customer.companyName}"`,
    });
    deleteCustomerUserMutation.mutate({customerId: customer.id, userId: user.id});
  };

  useEffect(() => {
    if (show) {
      if (notifications[notificationKey]) {
        setSubmitting(true);
      } else {
        setSubmitting(false);
      }
    }
  }, [user, customer, show, notifications, notificationKey]);

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={onClose}
      title="Remove User from Customer"
    >
      <Stack spacing="lg" align="stretch" style={{ position: 'relative' }}>
        <Stack spacing="xs" align="stretch">
          <Text size="sm">Are you sure to remove <Bold>{user && user.userName}</Bold> from <Bold> {customer?.companyName}</Bold>?</Text>
          {Boolean(user?.brands?.length) ?
            <Group spacing="xs" align="start" noWrap>
              <IconAlertCircle size={22} color="red" />
              <Text size="sm" style={{ fontStyle: 'italic'}}>
                The user is assigned to {user.brands.length > 1 ? 'these' : 'this'} {user.brands.length} brand{user.brands.length > 1 && 's'}: <Bold>{user.brands.map(brand => brand.brandName).join(', ')}</Bold>
              </Text>
            </Group> :
            <Group spacing="xs">
              <IconInfoCircle size={22} color="gray" />
              <Text size="sm" style={{ fontStyle: 'italic'}}>The user has no brands assigned</Text>
            </Group>
          }
        </Stack>
        <Group position="right">
          <Button color={Boolean(user?.brands?.length) ? 'red' : 'primary'} loading={submitting} onClick={() => handleDelete()}>
            Remove
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
