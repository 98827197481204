import { useContext, useEffect, useState } from 'react';
import { hideNotification } from '@mantine/notifications';
import { IconCrown } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';
import { patchCustomerUser } from '../../services/customers.service';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationsContext } from '../../providers/notifications.provider';
import { openConfirmationModal, openDeletionModal } from '../../utils/modals';
import { Bold } from '../common/bold.component';

export const CustomerUserSetAdminAction = ({ user, customer, setCustomerUsers, setActionInfo }) => {
  const { setSelectedCustomer } = useContext(AppContext);
  const { addNotification, deleteNotification, notifications } = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const companyName = customer?.companyName || '';
  const userName = user?.userName || '';
  const isUserAdmin = user.userAdmin;

  const id = `customer-user-set-admin-${user?.id}-${customer?.id}`;
  const title = `Updating "${companyName}" customer admins`;
  const startMessage = `${isUserAdmin ? 'Removing' : 'Adding'} "${userName}" ${isUserAdmin ? 'from' : 'to'} "${companyName}" customer admins`;
  const successMessage = `Successfully ${isUserAdmin ? 'removed' : 'added'} "${userName}" ${isUserAdmin ? 'from' : 'to'} "${companyName}" customer admins`;
  const failMessage = `Failed to ${isUserAdmin ? 'remove' : 'add'} "${userName}" ${isUserAdmin ? 'from' : 'to'} "${companyName}" customer admins`;

  const actionInfo = isUserAdmin ? `remove "${user.userName}" from customer admins` :  `add "${user.userName}" to customer admins`

  const toggleCustomerUserAdmin = async () => {
    addNotification(id);
    try {
      hideNotification(id);
      setStartNotification({ id, title, message: startMessage });

      await patchCustomerUser({ customer, user, payload: { userAdmin: !isUserAdmin }});

      const editedUser = { ...user, userAdmin: !isUserAdmin }
      queryClient.invalidateQueries({ queryKey: ['customerUsers', customer.id] });
      setSelectedCustomer(selectedCustomer => {
        if (selectedCustomer.id == customer.id) {
          setCustomerUsers(customerUsers => {
            return customerUsers.map(user => {
              if (user.id == editedUser.id) {
                return { ...editedUser };
              }
              return user;
            });
          });    
        }
        return selectedCustomer;
      });
      setSuccessNotification({ id, title, message: successMessage, autoClose: false });
    } catch (error) {
      setFailNotification({ id, title, message: failMessage });
    } finally {
      setLoading(false);
      deleteNotification(id);
    }
  };

  const handleAction = () => {
    if (isUserAdmin) {
      openDeletionModal({
        title: 'Remove Customer Admin',
        message: <>Are your sure to remove <Bold>{userName}</Bold> from <Bold>{companyName}</Bold> customer admins?</>,
        label: 'Remove',
        onConfirm: toggleCustomerUserAdmin,
      });
    } else {
      openConfirmationModal({
        title: 'Add Customer Admin',
        message:  <>Are your sure to add <Bold>{userName}</Bold> to <Bold>{companyName}</Bold> customer admins?</>,
        onConfirm: toggleCustomerUserAdmin,
      });
    }
  };

  useEffect(() => {
    if (notifications[id]) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }), [notifications, id, setLoading];

  return (
    <ActionIconWithInfo
      color={(isUserAdmin && !loading) ? 'yellow.7' : 'gray.3'}
      actionInfo={actionInfo}
      setActionInfo={setActionInfo}
      onClick={handleAction}
      loading={loading}
    >
      <IconCrown size={20} />
    </ActionIconWithInfo>
  );
};
