import axios from 'axios';
import { Env } from '../constants/env';

const apiUrl = `${Env.BASE_URL}api/v1/customers`;

export const getCustomers = () =>
  axios.get(`${apiUrl}`);

export const postCustomer = (customer) =>
  axios.post(`${apiUrl}`, customer);

export const patchCustomer = (customer) =>
  axios.patch(`${apiUrl}/${customer.id}`, customer);

export const deleteCustomer = (customer) =>
  axios.delete(`${apiUrl}/${customer.id}`);

export const getCustomerUsers = ({ queryKey: [_, customerId] }) =>
  axios.get(`${apiUrl}/${customerId}/users`);

export const createCustomerUser = ({ customer, user }) =>
  axios.post(`${apiUrl}/${customer.id}/users`, user);

export const postCustomerUser = ({ customer, user }) =>
  axios.post(`${apiUrl}/${customer.id}/users/${user.id}`);

export const patchCustomerUser = ({ customer, user, payload }) =>
  axios.patch(`${apiUrl}/${customer.id}/users/${user.id}`, payload);

export const deleteCustomerUser = ({ customerId, userId }) =>
  axios.delete(`${apiUrl}/${customerId}/users/${userId}`);

export const syncCustomerUsers = (customerId) =>
  axios.get(`${apiUrl}/${customerId}/users/sync`);

export const getCustomerBrands = ({ queryKey: [_, customerId] }) =>
  axios.get(`${apiUrl}/${customerId}/brands`);

export const postCustomerBrand = ({ customerId, brandName }) =>
  axios.post(`${apiUrl}/${customerId}/brands`, { brandName });

export const patchCustomerBrand = (brand) =>
  axios.patch(`${apiUrl}/${brand.customer.id}/brands/${brand.id}`, { brandName: brand.brandName });

export const deleteCustomerBrand = (brand) =>
  axios.delete(`${apiUrl}/${brand.customer.id}/brands/${brand.id}`);

export const getCustomerBrandUsers = ({ queryKey: [_, customerId, brandId] }) =>
  axios.get(`${apiUrl}/${customerId}/brands/${brandId}/users`);  

export const postCustomerBrandUser = ({ customer, brand, user }) =>
  axios.post(`${apiUrl}/${customer.id}/brands/${brand.id}/users/${user.id}`);

export const deleteCustomerBrandUser = ({ customerId, brandId, userId }) =>
  axios.delete(`${apiUrl}/${customerId}/brands/${brandId}/users/${userId}`);
