import React, { useContext, useState, forwardRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Autocomplete, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { getBrands } from '../../services/brands.service';

export const BrandSearch = ({ disabled = false, onSelect }) => {
  const { setSelectedBrand, setSelectedCustomer } = useContext(AppContext);
  const [value, setValue] = useState('');
  const { height } = useViewportSize();
  const { data: brands, isFetched: isFetchedBrands } = useQuery(
    ['brands'],
    getBrands,
  );

  const handleItemSubmit = (brand) => {
    setSelectedCustomer(brand.customer)
    setSelectedBrand(brand);
    setValue(brand.brandName);
    onSelect();

    const selectedCustomerElement = document.getElementById(`row-customer-${brand.customer.id}`)
    if (selectedCustomerElement) {
      selectedCustomerElement.scrollIntoView();
    }
  };

  return (
    <Autocomplete
      data={(brands?.data.map(brand => ({ value: brand.id.toString(), ...brand })) || [])}
      filter={(value, brand) => brand.brandName.toLocaleLowerCase().includes(value)}
      value={value}
      onChange={(value) => setValue(value)}
      onItemSubmit={handleItemSubmit}
      onDropdownClose={() => setValue('')}
      placeholder="Search brand"
      disabled={ disabled || !(isFetchedBrands)}
      itemComponent={forwardRef(({ brandName, customer, ...others }, ref) => (
        <div ref={ref} {...others}>
          {brandName}
          <Text size="xs" color="gray">
            {customer.companyName}
          </Text>
        </div>
      ))}
      icon={<IconSearch size={16} />}
      limit={brands?.data?.length || 0}
      maxDropdownHeight={Math.floor(height/2)}
      style={{ width: '220px' }}
      zIndex="600"
      radius={0}
    />
  )
};
