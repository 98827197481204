import axios from 'axios';
import { Env } from '../constants/env';

const apiUrl = `${Env.BASE_URL}api/v1/report`;

export const getLogonAttemptsReport = (userName = '', webApplicationId = 0) =>
  axios.get(`${apiUrl}/logonattempts?username=${userName}&webappid=${webApplicationId}`, { responseType: 'blob' });


export const getCustomerUsersReport = (customerId) =>
  axios.get(`${apiUrl}/customerusers?customerid=${customerId}`, { responseType: 'blob' });
