export const downloadExcelFile = (filename, data) => {
  const currentDatetime = new Date();
  const fomatedCurrentDate = currentDatetime.toLocaleDateString('en-US');
  const formatedCurrentTime = currentDatetime.toLocaleTimeString('en-US', { hour12: false });

  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.setAttribute('id', filename.toLowerCase().replace(' ', '-'));
  link.href = href;
  link.setAttribute('download', `${filename} ${fomatedCurrentDate} ${formatedCurrentTime}.xlsx`);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
