import { useContext, useEffect, useState } from 'react';
import { hideNotification } from '@mantine/notifications';
import { IconLogin } from '@tabler/icons-react';

import { NotificationsContext } from '../../providers/notifications.provider';
import { getLogonAttemptsReport } from '../../services/report.service';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';
import { downloadExcelFile } from '../../utils/download-excel-file';
import { ActionIconWithInfo } from '../common/action-icon-with-info.component';

export const UserLogonReportAction = ({ user, setActionInfo, customer = {} }) => {
  const { addNotification, deleteNotification, notifications } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const userName = user?.userName || '';

  const id = `export-logon-report-${user?.id}-${customer?.id}`;

  const handleAction = async () => {
    addNotification(id);
    try {
      hideNotification(id);
      setStartNotification({
        id,
        title: 'Logon attempts report',
        message: `Generating report for "${userName}" logon attempts to "${customer.address}"`,
      });

      const { data } = await getLogonAttemptsReport(userName, customer.webApplicationId);
      downloadExcelFile(`Logon Attempts for ${userName} to ${customer.address}`, data);
      
      setSuccessNotification({
        id,
        title: 'Logon attempts report',
        message: `Report for "${userName}" logon attempts to "${customer.address}" successfully generated, check your downloads`,
        autoClose: false,
      });
    } catch (error) {
      setFailNotification({
        id,
        title: 'Logon attempts report',
        message: `Report generation for "${userName}" loggon attempts to "${customer.address}" failed`,
      });
    } finally {
      setLoading(false);
      deleteNotification(id);
    }
  };

  useEffect(() => {
    if (notifications[id]) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }), [notifications, id, setLoading];

  return (
    <ActionIconWithInfo
      loading={loading}
      setActionInfo={setActionInfo}
      actionInfo={`export user "${user.userName}" logon attempts report`}
      onClick={handleAction}
    >
      <IconLogin size={16} />
    </ActionIconWithInfo>
  );
};
