import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { IconAlertCircle, IconInfoCircle } from '@tabler/icons-react';

import { NotificationsContext } from '../../providers/notifications.provider';
import { deleteUser } from '../../services/users.service';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';
import { Bold } from '../common/bold.component';

export const UserDeleteModal = ({ user, show, closeModal, onAccept }) => {
  const { notifications, addNotification, deleteNotification } = useContext(NotificationsContext);

  const [deleting, setDeleting] = useState(false);

  const notificationKey =  `deleting-user-${user.id}`;

  const removeUser = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      setSuccessNotification({
        id: notificationKey,
        title: 'Delete User',
        message: `User "${user.userName}" deleted successfully`,
      });
      onAccept(user);
    },
    onError: () => {
      setFailNotification({
        id: notificationKey,
        title: 'Delete User',
        message: `User "${user.userName}" was not deleted`,
      });
    },
    onSettled: () => {
      deleteNotification(notificationKey);
    },
  });

  const handleDelete = () => {
    addNotification(notificationKey, user);
    setStartNotification({
      id: notificationKey,
      title: 'Deleting User',
      message: `Deleting user "${user.userName}"`,
    });
    closeModal();
    removeUser.mutate(user);
  };
  
  useEffect(() => {
    if (show) {
      if (notifications[notificationKey]) {
        setDeleting(true);
      } else {
        setDeleting(false);
      }
    }
  }, [user, show, notifications, notificationKey]);

  const userCustomers = (user?.customers || []);

  return (
    <Modal
      size="md"
      zIndex="500"
      opened={show}
      onClose={closeModal}
      title="Delete User"
    >
      <Stack spacing="lg" align="stretch" style={{ position: 'relative' }}>
        <Stack spacing="xs" align="stretch">
          <Text size="sm">Are you sure to delete <Bold>{user && user.userName}</Bold>?</Text>
          {Boolean(userCustomers.length) ?
            <Group spacing="xs" align="start" noWrap>
              <IconAlertCircle size={22} color="red" />
              <Text size="sm" style={{ fontStyle: 'italic'}}>
                The user is assigned to {userCustomers.length > 1 ? 'these' : 'this'} {userCustomers.length} customer{userCustomers.length > 1 && 's'}: <Bold>{userCustomers.join(', ')}</Bold>
              </Text>
            </Group> :
            <Group spacing="xs">
              <IconInfoCircle size={22} color="gray" />
              <Text size="sm" style={{ fontStyle: 'italic'}}>The user has no customers assigned</Text>
            </Group>
          }
        </Stack>
        <Group position="right" mt="lg">
          <Button color={Boolean(userCustomers.length) ? 'red' : 'primary'} onClick={() => handleDelete()} loading={deleting}>
            Delete
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
