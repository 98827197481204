export const AppTheme = {
  colors: {
    // brand: ['#98d8ec', '#8ad3ea', '#7bcde7', '#6dc8e5', '#62b4ce', '#57a0b7', '#4c8ca0', '#417889', '#366472', '#2b505b'],
    // brand: ['#E0F2F1', '#B2DFDB', '#80CBC4', '#4DB6AC', '#26A69A', '#009688', '#00897B', '#00796B', '#00695C', '#004D40'],
    'dark-blue': ['#bec6dc', '#aeb8d4', '#9da9cb', '#8d9bc2', '#7d8dba', '#6d7fb1', '#5d71a9', '#536598', '#4a5a87', '#414f76'],
    'talkoot-brand': ['#c9dfe5', '#b3d2db', '#9ec5d1', '#88b8c7', '#72abbd', '#5d9fb2', '#4d8fa2', '#427c8d', '#386977', '#2e5661']
  },
  // primaryColor: 'teal',
  primaryColor: 'talkoot-brand',
  // primaryColor: 'dark-blue',
  fontFamily: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  lineHeight: '1.5rem',
};
