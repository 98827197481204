import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getRoles } from '../services/users.service';
import { getDefaultPasswordPolicy } from '../services/account.service';
import { getCustomers } from '../services/customers.service';

export const AppContext = React.createContext({});

export const AppProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedCustomerUser, setSelectedCustomerUser] = useState({});

  const [customers, setCustomers] = useState([]);

  const [roles, setRoles] = useState([]);
  const [defaultPasswordPolicy, setDefaultPasswordPolicy] = useState([]);

  const { isLoading: isLoadingRoles } = useQuery(
    ['roles'],
    getRoles,
    {
      onSuccess: ({ data: roles }) => {
        setRoles(roles);
      }
    });

  const { isLoading: isLoadingDefaultPasswordPolicy } = useQuery(
    ['defaultPasswordPolicy'],
    getDefaultPasswordPolicy,
    {
      onSuccess: ({ data: defaultPasswordPolicy }) => {
        setDefaultPasswordPolicy(defaultPasswordPolicy);
      },
    }
  );
  
  const { isLoading: isLoadingCustomers } = useQuery(
    ['customers'],
    getCustomers,
    {
      onSuccess: ({ data: customers }) => {
        setCustomers(customers);
        if (!selectedCustomer?.id) {
          const firstCustomer = customers[0];
          setSelectedCustomer(firstCustomer);
        }
      },
    }
  );

  const appContext = useMemo(
    () => ({
      selectedUser,
      setSelectedUser,
      selectedBrand,
      setSelectedBrand,
      selectedCustomer,
      setSelectedCustomer,
      selectedCustomerUser,
      setSelectedCustomerUser,
      customers,
      isLoadingCustomers,
      roles,
      isLoadingRoles,
      defaultPasswordPolicy,
      isLoadingDefaultPasswordPolicy,
    }),
    [
      selectedUser,
      setSelectedUser,
      selectedBrand,
      setSelectedBrand,
      selectedCustomer,
      setSelectedCustomer,
      selectedCustomerUser,
      setSelectedCustomerUser,
      customers,
      isLoadingCustomers,
      roles,
      isLoadingRoles,
      defaultPasswordPolicy,
      isLoadingDefaultPasswordPolicy,
    ]
  );

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
};
