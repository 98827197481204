import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Text,
  TextInput,
  PasswordInput,
  Group,
  Center,
  Image,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { AuthContext } from '../../providers/auth-provider';
import logo from '../../assets/talkoot_transparent_460x126.png'

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [failedLogin, setFailedLogin] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const { login } = useContext(AuthContext);

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
  });

  const navigate = useNavigate();

  const onSubmit = ({ username, password }) => {
    setLoading(true);
    setFailedLogin(false);
    let loggedIn = false;
    login(username, password)
      .then((response) => {
        loggedIn = response.success;
        if (!loggedIn) {
          setFailedLogin(true);
          setFailedMessage(response.message);
        }
      })
      .finally(() => {
        setLoading(false);
        if (loggedIn) {
          navigate('/app/customers');
        }
      });
  };

  return (
    <Center style={{ width: '100%', height: '100vh' }}>
      <Stack spacing={0} align="center" style={{ marginTop: '-120px' }}>
        <Image width={300} src={logo} alt="Talkoot" />
        <Text weight={600} color="dimmed" align="center" mt="xs" mb="md">
          Identity Provider
        </Text>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack style={{ width: '350px' }}>
            <TextInput
              required
              label="Username"
              {...form.getInputProps('username')}
              disabled={loading}
            />
            <PasswordInput
              required
              label="Password"
              {...form.getInputProps('password')}
              disabled={loading}
            />
            <Text color="red" size="sm">
              {Boolean(failedLogin) && (failedMessage || 'Incorrect username or passsword')}
            </Text>
            <Button type="submit" loaderPosition="right" loading={loading} fullWidth>
              Sign in
            </Button>
          </Stack>
        </form>
        <Group position="center" mt="lg">
          <Button
            compact
            variant="white"
            disabled={loading}
            onClick={() => navigate('/forgot-password')}
          >
            Forgot your password?
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
