import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Divider, Group, LoadingOverlay, Modal, Select, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';

import { AppContext } from '../../providers/app-provider';
import { NotificationsContext } from '../../providers/notifications.provider';
import { updateUser } from '../../services/users.service';
import { Label } from '../common/label.component';
import { setFailNotification, setStartNotification, setSuccessNotification } from '../../utils/notifications';

const getInitialValues = (user) => ({
  userName: user.userName || '',
  email: user.email || '',
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  displayName: user.displayName || '',
  jobTitle: user.jobTitle || '',
  role: user.role || '',
});

const validationSchema = z.object({
  userName: z.string().min(1, { message: 'Username is required' }),
  email: z.string().min(1, { message: 'Email is required' }).email({ message: 'Invalid email' }),
  role: z.string().min(1, { message: 'Role is required' }),
});

export const UserEditModal = ({ user, show, closeModal, onAccept }) => {
  const { roles, isLoadingRoles } = useContext(AppContext);
  const { notifications, addNotification, deleteNotification } = useContext(NotificationsContext);
  
  const [ submitting, setSubmitting ] = useState(false);

  const notificationKey = `editing-user-${user.id}`;

  const form = useForm({
    schema: zodResolver(validationSchema),
    initialValues: getInitialValues({}),
  });

  const editUser = useMutation({
    mutationFn: updateUser,
    onSuccess: (_, variables) => {
      const editedUser = variables;
      setSuccessNotification({
        id: notificationKey,
        title: 'Update User',
        message: `User "${editedUser.userName}" updated successfully`,
      });
      onAccept(editedUser);
    },
    onError: () => {
      setFailNotification({
        id: notificationKey,
        title: 'Update User',
        message: `User "${user.userName}" was not updated`,
      });
    },
    onSettled: () => {
      deleteNotification(notificationKey);
    },
  });

  const onSubmit = useCallback(
    (values) => {
      setSubmitting(true);
      const editedUser = { ...values, id: user.id };
      addNotification(notificationKey, editedUser);
      setStartNotification({
        id: notificationKey,
        title: 'Updating User',
        message: `Updating user "${editedUser.userName}"`,
      });
      editUser.mutate(editedUser, {
        onSettled: () => {
          setSubmitting(false);
        }
      });
    },
    [
      user,
      editUser,
      setSubmitting,
      notificationKey,
      addNotification,
      setStartNotification,
    ]
  );

  useEffect(() => {
    if (show) {
      if (notifications[notificationKey]) {
        form.setValues(getInitialValues(notifications[notificationKey]))
        setSubmitting(true);
      } else {
        form.setValues(getInitialValues(user));
        setSubmitting(false);
      }
    }
  }, [user, show, notifications, notificationKey]);

  return (
    <Modal
      size="md"
      // zIndex="500"
      opened={show}
      onClose={closeModal}
      title="Edit User"
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <LoadingOverlay visible={isLoadingRoles} />
        <Stack>
          <TextInput
            placeholder="Username"
            label={<Label>Username</Label>}
            disabled={true} // Username is not editable
            {...form.getInputProps('userName')}
          />
          <TextInput
            placeholder="your@email.com"
            label={<Label required>E-mail</Label>}
            disabled={submitting}
            {...form.getInputProps('email')}
          />
          <Select
            zIndex="600"
            data={roles.map(role => ({ value: role.name, label: role.name }))}
            label={<Label required>Role</Label>}
            placeholder="User Role"
            disabled={submitting}
            searchable
            {...form.getInputProps('role')}
          />
          <Divider label="Optional User Details" labelPosition="center" />
          <Group grow>
            <TextInput
              placeholder="First name"
              label="First Name"
              disabled={submitting}
              {...form.getInputProps('firstName')}
            />
            <TextInput
              placeholder="Last name"
              label="Last Name"
              disabled={submitting}
              {...form.getInputProps('lastName')}
            />
          </Group>
          <Group grow>
            <TextInput
              placeholder="Display name"
              label="Display name"
              disabled={submitting}
              {...form.getInputProps('displayName')}
            />
            <TextInput
              placeholder="Job title"
              label="Job title"
              disabled={submitting}
              {...form.getInputProps('jobTitle')}
            />
          </Group>
        </Stack>
        <Group spacing="xl" position="right" style={{ marginTop: '24px' }}>
          <Button type="submit" loading={submitting}>
            Update
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
