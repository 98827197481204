import axios from 'axios';
import { Env } from '../constants/env';

const apiUrl = `${Env.BASE_URL}api/v1/users`;

export const getRoles = () =>
  axios.get(`${apiUrl}/roles`);

export const addUser = (user) => 
  axios.post(`${apiUrl}`, user);

export const updateUser = (user) => 
  axios.patch(`${apiUrl}/${user.id}`, user);

export const deleteUser = (user) => 
  axios.delete(`${apiUrl}/${user.id}`);

export const getUserBrands = ({ queryKey: [, userId] }) => 
  axios.get(`${apiUrl}/${userId}/brands`);

export const syncUser = (userId, customerId = 0) =>
  axios.post(`${apiUrl}/${userId}/sync${Boolean(customerId) ? `?customerId=${customerId}` : ''}`);

export const getUsers = ({ queryKey: [, userName, perPage, page, orderQuery] }) =>
  axios.get(`${apiUrl}?userName=${userName}&pageSize=${perPage}&pageNumber=${page}&orderBy=${orderQuery}`);
