import React from 'react';
import { Modal, Group, Button, Anchor } from '@mantine/core';

export const WebAppsList = ({ opened, closeModal, items }) => {
  const listItems = items.map((item) => {
    return (
      <li key={item}>
        <Anchor  href={item}>{item}</Anchor>
      </li>
    );
  });

  return (
    <Modal opened={opened} onClose={closeModal} title='Available apps for user'>
      <Group grow mb='sm' mt='lg'>
        <ul style={{listStyle: 'none'}}>{listItems}</ul>
      </Group>
      <Group spacing='xl' position='right' style={{ marginTop: '24px' }}>
        <Button onClick={closeModal}>Close</Button>
      </Group>
    </Modal>
  );
};

export default WebAppsList;
