// import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import { AuthProvider } from './providers/auth-provider';
import { jwtInterceptor } from './providers/jwt-interceptor';

import { App } from './components/app.component';
import { AppTheme } from './components/app.theme';
import { Login } from './components/auth/login.component';
import { ForgotPassword } from './components/auth/forgot-password';
import { ResetPassword } from './components/auth/reset-password';
import { appInsights, reactPlugin } from './utils/app-insights';

import './style.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

jwtInterceptor();
appInsights.loadAppInsights();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = createRoot(document.getElementById('root'));

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={AppTheme} withNormalizeCSS>
        <Notifications position="bottom-right" containerWidth={500} zIndex={1000} limit={10} />
        <ModalsProvider>
          <AuthProvider>
            <BrowserRouter basename={baseUrl}>
              <Routes>
                <Route path='/' element={<Navigate replace to='login' />} />
                <Route path='login' element={<Login />} />
                <Route path='forgot-password' element={<ForgotPassword /> } />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='app/*' element={<App />} />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ModalsProvider>
      </MantineProvider>
    </QueryClientProvider>
  </AppInsightsContext.Provider>
);
