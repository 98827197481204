import React, { useMemo, useState } from 'react';

export const ActionInfoContext = React.createContext({});

export const ActionInfoProvider = ({ children }) => {

  const [customerActionInfo, setCustomerActionInfo] = useState('');
  const [brandActionInfo, setBrandActionInfo] = useState('');

  const appContext = useMemo(
    () => ({
      brandActionInfo,
      setBrandActionInfo,
      customerActionInfo,
      setCustomerActionInfo,
    }),
    [
      brandActionInfo,
      setBrandActionInfo,
      customerActionInfo,
      setCustomerActionInfo,
    ]
  );

  return (
    <ActionInfoContext.Provider value={appContext}>
      {children}
    </ActionInfoContext.Provider>
  );
}