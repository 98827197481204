export const getPasswordStrength = (password, requirements) => {
  let multiplier = 0;

  (requirements || []).forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / ((requirements || []).length + 1)) * multiplier, 0);
};
  
export const getPasswordRequirements = (passwordPolicy) => {
  const requirements = [];
  
  requirements.push({
    re: new RegExp('^.{' + passwordPolicy.requiredLength + ',}$'),
    label: `Must be at least ${passwordPolicy.requiredLength} characters`,
  });

  requirements.push({
    re: {
      test: (value) =>
        [...new Set(value)].join('').length >=
        passwordPolicy.requiredUniqueChars,
    },
    label: `Must use at least ${passwordPolicy.requiredUniqueChars} different characters`,
  });

  if (passwordPolicy.requireDigit) {
    requirements.push({
      re: /[0-9]/,
      label: "Must have at least one digit ('0'-'9')",
    });
  }

  if (passwordPolicy.requireLowercase) {
    requirements.push({
      re: /[a-z]/,
      label: "Must have at least one lowercase ('a'-'z').",
    });
  }

  if (passwordPolicy.requireUppercase) {
    requirements.push({
      re: /[A-Z]/,
      label: "Must have at least one uppercase ('A'-'Z')",
    });
  }

  if (passwordPolicy.requireNonAlphanumeric) {
    requirements.push({
      re: /[$&+,:;=?@#|'<>.^*()%!-]/,
      label: 'Must have at least one non alphanumeric character',
    });
  }

  return requirements;
}
