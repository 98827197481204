import { useContext } from 'react';
import { Button, Group, Text } from '@mantine/core';
import { IconUserPlus } from '@tabler/icons-react';

import { AppContext } from '../../providers/app-provider';
import { FilterInput } from '../common/filter-input.component';

export const BrandUsersToolbar = ({
  isLoadingBrandUsers,
  brandUsers,
  searchBrandUserValue,
  filteredBrandUsers,
  setSearchBrandUserValue,
  setAddBrandUserMode,
}) => {
  const { selectedBrand, selectedCustomer } = useContext(AppContext);

  return (
    <Group p="xs" m={0} position="apart" style={{ backgroundColor: '#f6f6f6',  borderBottom: '1px solid #dee2e6'}}>
      <Group>
        <FilterInput
          placeholder="Filter by username or email"
          setFilterTerm={setSearchBrandUserValue}
          filterTerm={searchBrandUserValue}
          disabled={
            isLoadingBrandUsers
            || !selectedCustomer?.id
            || !selectedBrand?.id
            || !brandUsers?.length
          }
          width="260px"
        />
        <Text size="sm" color="dark">
          {(brandUsers.length && searchBrandUserValue) ? `${filteredBrandUsers.length} of ` : ''}
          {Boolean(brandUsers.length) && `${brandUsers.length} users`}
        </Text>
      </Group>
      {!(isLoadingBrandUsers || !selectedCustomer?.id || !selectedBrand?.id) &&
        <Button
          onClick={() => {
            setAddBrandUserMode(true);
            setSearchBrandUserValue('');
          }}
          disabled={
            isLoadingBrandUsers
            || !selectedCustomer?.id
            || !selectedBrand?.id
          }
          style={{ backgroundColor: '#5d71a9' }}
          leftIcon={<IconUserPlus size={16} />}
        >
          Add User to Brand
        </Button>
      }
    </Group>
  );
};
