import { useContext } from 'react';
import { Group } from '@mantine/core';

import { AuthContext } from '../../providers/auth-provider';
import { CustomersSelect } from './customers-select.component';
import { BrandSearch } from '../brands/brand-search.component';
import { BrandAddModal } from '../brands/brand-add-modal.component';
import { CustomerAddModal } from './customer-add-modal.component';

export const CustomerAdminToolbar = ({ setActiveTab }) => {
  const { isAdmin } = useContext(AuthContext);

  return (
    <Group spacing="xs" mb="xs" position="apart" noWrap>
      <Group style={{ flexGrow: 1 }} noWrap>
        <CustomersSelect />
        <BrandSearch
          onSelect={() => setActiveTab('brands')}
        />
      </Group>
      <Group noWrap>
        {isAdmin &&
          <>
            <CustomerAddModal />
            <BrandAddModal setActiveTab={setActiveTab}/>
          </>
        }
      </Group>
    </Group>
  );
};
