import { useContext } from 'react';

import { ActionInfoContext } from '../../providers/action-info.provider';
import { InfoText } from './info-text.component';

export const BrandActionInfo = () => {
  const { brandActionInfo } = useContext(ActionInfoContext);

  return (
    <InfoText>{brandActionInfo}</InfoText>
  );
};
