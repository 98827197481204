import React, { useCallback, useMemo, useState } from 'react';

export const NotificationsContext = React.createContext({});

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState({});

  const addNotification = useCallback(
    (notificationId, payload = {}) => {
      setNotifications(notifications => ({
        ...notifications,
        [notificationId]: {...payload},
      }));
    },
    [setNotifications],
  );

  const deleteNotification = useCallback(
    (notificationId) => {
      setNotifications(notifications => {
        delete notifications[notificationId];
        return notifications;
      });
    },
    [setNotifications],
  );

  const notificationsContext = useMemo(
    () => ({
      notifications,
      addNotification,
      deleteNotification,
    }),
    [
      notifications,
      addNotification,
      deleteNotification,
    ]
  );

  return (
    <NotificationsContext.Provider value={notificationsContext}>
      {children}
    </NotificationsContext.Provider>
  );
}