import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useViewportSize } from '@mantine/hooks';
import { LoadingOverlay, Paper, Stack, Text } from '@mantine/core';
import { IconUserSearch } from '@tabler/icons-react';
import DataTable from 'react-data-table-component';

import { AuthContext } from '../../providers/auth-provider';
import { AppContext } from '../../providers/app-provider';
import { ActionInfoContext } from '../../providers/action-info.provider';
import { getCustomerUsers } from '../../services/customers.service';
import { CustomerUserTableActions } from './customer-users-table-actions.component';
import { CustomerAddUserToolbar } from './customer-add-user-toolbar.component';
import { CustomerUsersToolbar } from './customer-users-toolbar.component';
import { sortBy } from '../../utils/sort-by';
import { CustomerUserSetAdminAction } from './customer-user-set-admin-action.component';

const paginationOptions = {
  noRowsPerPage: true
};

export const CustomerUsersTable = ({ isInView }) => {
  const { isAdmin } = useContext(AuthContext);
  const { selectedCustomer } = useContext(AppContext);
  const { setCustomerActionInfo } = useContext(ActionInfoContext);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [searchCustomerUserValue, setSearchCustomerUserValue] = useState('')
  const [addCustomerUserMode, setAddCustomerUserMode] = useState(false);
  const { height } = useViewportSize();

  const { isLoading: isLoadingCustomerUsers, data: customerUsersData } = useQuery(
    ['customerUsers', selectedCustomer.id],
    getCustomerUsers,
    {
      enabled: isInView && Boolean(selectedCustomer?.id),
    }
  );

  useEffect(() => {
    setCustomerUsers(customerUsersData?.data || []);
  }, [customerUsersData]);

  const filteredCustomerUsers = useMemo(() => {
    let filteredUsers = [...customerUsers];
    
    if (searchCustomerUserValue) {
      return filteredUsers.filter((user) =>
        (
          (user.userName || '')
            .toLocaleLowerCase()
            .includes(searchCustomerUserValue.toLocaleLowerCase()) ||
          (user.email || '')
            .toLocaleLowerCase()
            .includes(searchCustomerUserValue.toLocaleLowerCase())
        )
      );
    }
    return filteredUsers;
  }, [customerUsers, searchCustomerUserValue]);

  const customerAdminCell = useCallback(
    (user) => (
      <CustomerUserSetAdminAction
        user={user}
        customer={selectedCustomer}
        setCustomerUsers={setCustomerUsers}
        setActionInfo={setCustomerActionInfo}
      />
    ),
    [selectedCustomer, setCustomerUsers, setCustomerActionInfo],
  );

  const customerUserActionsCell = useCallback(
    (user) => (
      <CustomerUserTableActions
        user={user}
        setCustomerUsers={setCustomerUsers}
      />
    ),
    [setCustomerUsers],
  );

  const customerUserColumns = useMemo(
    () => ([
      {
        name: 'userId',
        selector: (user) => user.id,
        omit: true,
      },
      {
        name: 'Username',
        selector: (user) => user.userName,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'userName'),
        grow: 1,
      },
      {
        name: 'E-mail',
        selector: (user) => user.email,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'email'),
        grow: 1,
      },
      {
        name: 'Display Name',
        selector: (user) => user.displayName,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'displayName'),
        width: '160px',
      },
      {
        name: 'Role',
        selector: (user) => user.role,
        sortable: true,
        sortFunction: (a, b) => sortBy(a, b, 'role'),
        width: '90px',
      },
      {
        name: 'Customer Admin',
        subHeaderAlign: 'center',
        selector: (user) => user.userAdmin,
        sortable: true,
        center: true,
        sortFunction: (a, b) => sortBy(a, b, 'userAdmin'),
        width: '150px',
        cell: customerAdminCell,
      },
      {
        name: 'Actions',
        center: true,
        width: '180px',
        cell: customerUserActionsCell,
      },
    ].filter(column => column.name !== 'Customer Admin'|| isAdmin)),
    [sortBy, customerAdminCell, customerUserActionsCell, isAdmin],
  );

  const fixedHeaderScrollHeight = useMemo(() => `${height-234}px`, [height]);

  const containerStyles = useMemo(
    () => ({
      height: `${height-236}px`,
      width: 'calc(100vw - 264px)',
      position: 'relative',
    }),
    [height]
  );

  const customStyles = useMemo(
    () => ({
      table: { style: { maxHeight: `${height-328}px` }},
    }),
    [height],
  );

  const noDataComponent = useMemo(() => (selectedCustomer?.id && isLoadingCustomerUsers) ? '' : (
    <Paper p="xs">
      {(selectedCustomer?.id && !customerUsers.length) ?
        <Stack align="center" mt="sm">
          <IconUserSearch size={32} color="gray" />
          <Text size="sm" color="gray"><em>This customer has no users</em></Text>
        </Stack> : 
        <Text size="sm" color="gray">
          <em>{searchCustomerUserValue ? 'No users found' : 'Select a customer'}</em>
        </Text>
      }
    </Paper>
  ), [selectedCustomer, isLoadingCustomerUsers, customerUsers, searchCustomerUserValue]);

  return (
    <Stack spacing={0}>
      {addCustomerUserMode ?
        <CustomerAddUserToolbar
          customerUsers={customerUsers}
          setCustomerUsers={setCustomerUsers}
          setAddCustomerUserMode={setAddCustomerUserMode}
        /> :
        <CustomerUsersToolbar
          isLoadingCustomerUsers={isLoadingCustomerUsers}
          customerUsers={customerUsers}
          setCustomerUsers={setCustomerUsers}
          filteredCustomerUsers={filteredCustomerUsers}
          searchCustomerUserValue={searchCustomerUserValue}
          setSearchCustomerUserValue={setSearchCustomerUserValue}
          setAddCustomerUserMode={setAddCustomerUserMode}
          />
      }
      <Paper
        m="xs"
        radius={0}
        withBorder
        style={containerStyles}
      >
        <LoadingOverlay visible={!selectedCustomer?.id || isLoadingCustomerUsers}></LoadingOverlay>
        <DataTable
          columns={customerUserColumns}
          data={filteredCustomerUsers}
          highlightOnHover
          // pointerOnHover
          selectableRowsSingle
          dense
          fixedHeader
          fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          pagination
          paginationPerPage={20}
          paginationComponentOptions={paginationOptions}
          customStyles={customStyles}
          noDataComponent={noDataComponent}
        />
      </Paper>
    </Stack>
  );
};
