import React, { useContext, useEffect, useState } from 'react';
import { Button, Group, Modal, Text, PasswordInput, Card, Stack } from '@mantine/core';
import { IconCopy, IconLink, IconLockOpen, IconSend } from '@tabler/icons-react';

import { AuthContext } from '../../providers/auth-provider';
import { forgotPassword, adminResetPassword, getResetPasswordLink } from '../../services/account.service';
import { showErrorNotification, showSuccessNotification } from '../../utils/notifications';
import { Bold } from '../common/bold.component';

export const UserResetPasswordModal = ({ user, show, closeModal }) => {
  const { isAdmin } = useContext(AuthContext);
  const [changingPasssword, setChangingPasssword] = useState(false);
  const [gettingResetLink, setGettingResetLink] = useState(false);
  const [sendingResetLink, setSendingResetLink] = useState(false);
  const [password, setPassword] = useState('');
  const [resetLink, setResetLink] = useState('');

  const handleGetResetLink = async () => {
    setGettingResetLink(true);
    setResetLink('');
    try {
      const response = await getResetPasswordLink(user.userName);
      setResetLink(response.data);
      showSuccessNotification({
        title: 'Reset password link generated',
        message: 'Copy and hand the link to the user.',
      });
    } catch (error) {
      showErrorNotification({
        message: error?.message ? error.message : '',
      });
    } finally {
      setGettingResetLink(false);
    }
  };

  const handleSendMail = async () => {
    setSendingResetLink(true);
    try {
      await forgotPassword(user.userName);
      showSuccessNotification({
        title: 'Reset password email sent',
        message: 'Ask the user to check their email inbox.',
      });
    } catch (error) {
      showErrorNotification({
        message: error?.message ? error.message : '',
      });
    } finally {
      setSendingResetLink(false);
    }
  };

  const handleResetManually = async () => {
    setChangingPasssword(true);
    try {
      await adminResetPassword({
        userName: user.userName,
        password: password
      });
      showSuccessNotification({
        title: 'Reset password success',
        message: 'The password has been successfully changed.',
      });
    } catch (error) {
      showErrorNotification({
        title: 'Failed to reset password',
        message: error?.message ? error.message : '',
      });
    } finally {
      setChangingPasssword(false);
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  useEffect(() => {
    if (show) {
      setPassword('');
      setResetLink('');
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      zIndex="500"
      opened={show}
      onClose={(gettingResetLink || sendingResetLink || changingPasssword) ? () => {} : closeModal}
      title="Reset User Password"
    >
      <Stack spacing="xs">
        <Text size="sm">Reset password options for <Bold>{user.userName}</Bold></Text>
        <Card p="xs" withBorder>
          <Group position="apart">
            <Group spacing={5}>
              <IconLink size={16} />
              <Text weight={500} size="sm">Get a reset password link</Text>
            </Group>
          </Group>
          <Group position="apart" mt="sm">
            <Text size="sm">Generate a new reset password link to hand to the user</Text>
          </Group>
          <Group position="right" mt="sm">
            <Button
              variant="subtle"
              rightIcon={<IconCopy size={18} />}
              onClick={() => copyToClipboard(resetLink)}
              styles={{ rightIcon: { marginLeft: '5px' }}}
              style={{ width: '140px' }}
              disabled={!resetLink || gettingResetLink}
              compact
            >
              Copy reset link
            </Button>
            <Group apart="xs">
              <Button
                onClick={() => handleGetResetLink()}
                loading={gettingResetLink}
                style={{ width: '140px' }}
              >
                {gettingResetLink ? 'Getting' : 'Get link'}
              </Button>
            </Group>
          </Group>
        </Card>
        
        <Card p="xs" withBorder>
          <Group position="apart">
            <Group spacing={5}>
              <IconSend size={16} />
              <Text weight={500} size="sm">Send reset password link</Text>
            </Group>
          </Group>
          <Group position="apart" mt="sm">
            <Text size="sm">The reset password link will be sent to <Bold>{user.email}</Bold></Text>
          </Group>
          <Group position="right" mt="sm">
            <Button
              variant="subtle"
              rightIcon={<IconCopy size={18} />}
              onClick={() => copyToClipboard(user.email)}
              styles={{ rightIcon: { marginLeft: '5px' }}}
              style={{ width: '170px' }}
              compact
            >
              Copy email address
            </Button>
            <Button
              onClick={() => handleSendMail()}
              loading={sendingResetLink}
              style={{ width: '140px' }}
            >
              {sendingResetLink ? 'Sending' : 'Send link'}
            </Button>
          </Group>
        </Card>
        {isAdmin && (
          <Card p="xs" withBorder>
            <Group position="apart">
              <Group spacing={5}>
                <IconLockOpen size={16} />
                <Text weight={500} size="sm">Change user password</Text>
              </Group>
            </Group>
            <Group position="apart" mt="sm" spacing="lg" grow>
              <Text size="sm">Set a new password for the user</Text>
              <PasswordInput
                style={{ flexGrow : 1 }}
                placeholder="New password"
                autoComplete="new-password"
                value={password}
                onChange={(event)=> setPassword(event.currentTarget.value)}
              />
            </Group>
            <Group position="right" mt="sm">
              <Button
                variant="subtle"
                onClick={() => copyToClipboard(password)}
                rightIcon={<IconCopy size={18} />}
                styles={{ rightIcon: { marginLeft: '5px' }}}
                style={{ width: '140px' }}
                disabled={!password}
                compact
              >
                Copy password
              </Button>
              <Button
                onClick={() => handleResetManually()}
                loading={changingPasssword}
                disabled={!password}
                style={{ width: '140px' }}
              >
                {changingPasssword ? 'Changing' : 'Change' }
              </Button>
            </Group>
          </Card>
        )}
      </Stack>
    </Modal>
  );
};
