import React from 'react';
import { Group, Progress } from '@mantine/core';
import { getPasswordStrength } from './password.helpers';

export const PasswordStrengthBars = ({ password, requirements }) => {
  const strength = getPasswordStrength(password, requirements);
  return (
    <Group spacing={5} mt={10} grow>
      {Array((requirements || []).length)
      .fill(0)
      .map((_, index) => (
        <Progress
          styles={{ bar: { transitionDuration: '0ms' } }}
          value={
            password.length > 0 && index === 0
              ? 100
              : strength >= ((index + 1) / 6) * 100
              ? 100
              : 0
          }
          color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
          key={index}
          size={(requirements || []).length}
        />
      ))}
    </Group>
  )
};
